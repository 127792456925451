import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input, notification } from 'antd';
import { useExportQuoteQuery } from '../../../../hooks/react-query/useExportQuote';
import download from 'downloadjs';
import { pruneEmpty } from '../../../../utils/utils';
import moment from 'moment';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

export interface IExportQuoteForm {
    orderId: string;
}

const ExportQuoteView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [lastUpdate, setLastUpdate] = useState<string>(moment().toISOString());

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const { register, handleSubmit, control, formState, watch } = useForm<IExportQuoteForm>({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object().shape({
                orderId: yup
                    .string()
                    .nullable()
                    .required(t('screens.private.export.tabs.quote.form.orderId_require')),
            }),
        ),
    });

    const watchFields = watch();

    // Subscription to a fetch query
    const { refetch, isLoading: isFetchingQuote } = useExportQuoteQuery(
        pruneEmpty({
            orderId: watchFields.orderId,
        }),
        (responseData: any) => {
            download(responseData.content, responseData.fileName, responseData.contentType);

            setLastUpdate(moment().toISOString());

            // Print success to the user
            notification.success({
                message: t('success.general.title'),
                description: t('success.export_completed'),
            });
        },
    );

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Handle submit form
     *
     * @function
     * @returns {void}
     */
    const handleSubmitForm = (): void => {
        refetch();
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ExportQuoteView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ExportQuoteView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <>
            <Form
                key={lastUpdate}
                layout="vertical"
                size="large"
                onFinish={handleSubmit(handleSubmitForm)}
                className="export-quote-container"
            >
                <Form.Item
                    validateStatus={!!formState.errors.orderId ? 'error' : ''}
                    help={formState.errors.orderId?.message}
                    label={t('screens.private.export.tabs.quote.form.orderId.title')}
                >
                    <Controller
                        as={
                            <Input
                                title="orderId"
                                type="text"
                                placeholder={t(
                                    'screens.private.export.tabs.quote.form.orderId.placeholder',
                                )}
                                ref={register}
                                disabled={isFetchingQuote}
                                autoFocus
                            />
                        }
                        name="orderId"
                        control={control}
                    />
                </Form.Item>

                <div className="bottom-container">
                    <Form.Item>
                        <p>{t('screens.private.export.tabs.quote.bottom.text')}</p>
                        <Button
                            type="primary"
                            disabled={!formState.isValid}
                            loading={isFetchingQuote}
                            htmlType="submit"
                        >
                            {t('screens.private.export.tabs.quote.bottom.button')}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};
export default ExportQuoteView;
