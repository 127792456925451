/**
 * Local storage objects
 */
export enum LOCAL_STORAGE {
    JWT = 'jwt',
}

export enum DB_COLLECTIONS {
    USERS = 'users',
}
