import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Input, List, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/lib/table';
import debounce from 'lodash/debounce';
import { getColumnValueByDataIndex, pruneEmpty } from '../../utils/utils';
import { DB_DEFAULTS } from '../common/data/enums';
import useQueries from '../../hooks/useQueries';
import { useQuery } from 'react-query';
import { QUERIES_KEYS } from '../../data/enums';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    // Required params
    columns: any;
    counterMethod: (filtersIgnored?: Array<string>, searchIgnored?: string) => Promise<number>;
    data: Array<any>;
    mobileColumnTitle: Array<string>;
    loadMethod: any;
    onLoadData: (dataIgnored: Array<any>) => void;
    viewportWidth: number;
    queryKey: QUERIES_KEYS;

    // Optional params
    entityName?: string;
    expandable?: object;
    onAddRow?: () => void;
    onDownload?: () => void;
    downloadLabel?: string;
    placeholder?: string;
    minSmWidth?: number;
}

const TableList = ({
    columns,
    counterMethod,
    data,
    queryKey,
    mobileColumnTitle,
    loadMethod,
    onLoadData,
    viewportWidth,
    entityName,
    expandable,
    onAddRow,
    onDownload,
    downloadLabel,
    placeholder,
    minSmWidth = 768,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Instant interval
    const dataRefreshIntervalInstance: any = useRef();

    // If set to a number, all queries will continuously refetch at this frequency in milliseconds
    const refetchInterval = 30000;

    // Get shared products utility function
    const { getFetchRequestQueryParams }: any = useQueries();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // Pagination
    const [limit] = useState<number>(DB_DEFAULTS.LIMIT);
    const [pagination, setPagination] = useState<TablePaginationConfig | undefined>(undefined);
    const [sorter, setSorter] = useState<any>(undefined);

    // Filters search
    const [search, setSearch] = useState<string | undefined>(undefined);

    // Original data & pagination for reset search
    const [originalData, setOriginalData] = useState<Array<any>>([]);
    const [originalPagination, setOriginalPagination] = useState<TablePaginationConfig | undefined>(
        undefined,
    );

    // Subscription to a fetch query
    const { data: fetchedData, isLoading } = useQuery<Array<any>>(
        [
            queryKey,
            {
                ...loadMethod.params,
                ...pruneEmpty({
                    filters: loadMethod.filters,
                    limit: pagination?.pageSize,
                    offset:
                        !!pagination && pagination.current && pagination.pageSize
                            ? (pagination.current - 1) * pagination.pageSize
                            : undefined,
                    search,
                    sorter,
                }),
            },
        ],
        ({ queryKey: payload }: any) => loadMethod.name(payload[1]),
        getFetchRequestQueryParams({ enabled: !!pagination, refetchInterval }),
    );

    // Subscription to a fetch query
    const { data: dataCount, isLoading: isCountingData } = useQuery<number>(
        [
            `${queryKey}Count`,
            {
                filter_fields: loadMethod.filters,
                search,
            },
        ],
        ({ queryKey: payload }: any) => {
            return counterMethod(payload[1].filter_fields, payload[1].search);
        },
        getFetchRequestQueryParams({ refetchInterval }),
    );

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Set search by user text
     *
     * @function
     */
    const onSearchChange = debounce(async (e: any) => {
        setSearch(e.target.value.trim().length >= 3 ? e.target.value.trim() : '');
    }, 1000);

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render List
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderList = (): ReactElement | null => (
        <div className="list-card">
            <List
                itemLayout="vertical"
                size="small"
                pagination={{
                    position: 'both',
                    current: pagination?.current || 1,
                    showSizeChanger: false,
                    total: pagination?.total || 0,
                    pageSize: limit,
                    onChange: (page, pageSize) => {
                        setPagination({
                            ...pagination,
                            current: page,
                            pageSize,
                        });
                    },
                }}
                loading={isLoading || isCountingData}
                dataSource={data}
                renderItem={(item: any, index) => (
                    <List.Item key={index}>
                        <Card>
                            {/* <List.Item.Meta
                                title={mobileColumnTitle.map(
                                    (prop, i) =>
                                        `${i > 0 ? ' ' : ''}${getObjPropByString(item, prop)}`,
                                )}
                            /> */}
                            {columns.map((c: any) => (
                                <>
                                    {/* Exclude fields rendered in the line title as they do not need to be rendered again in the content of the element */}
                                    {(!c.dataIndex ||
                                        c.dataIndex.length > 1 ||
                                        !mobileColumnTitle.includes(c.dataIndex[0])) && (
                                        <div className="content">
                                            <div>
                                                <strong>{c.title}</strong>{' '}
                                            </div>
                                            <div>
                                                {c.render
                                                    ? c.render(
                                                          c.dataIndex
                                                              ? getColumnValueByDataIndex(
                                                                    item,
                                                                    c.dataIndex,
                                                                )
                                                              : item,
                                                      )
                                                    : getColumnValueByDataIndex(item, c.dataIndex)}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );

    /**
     * Render earch
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderSearch = (): ReactElement | null =>
        !!placeholder ? (
            <Input
                className="table-search"
                size="large"
                placeholder={placeholder || t('search')}
                prefix={<SearchOutlined />}
                onChange={onSearchChange}
                allowClear
            />
        ) : null;

    /**
     * Render Table
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderTable = (): ReactElement | null => (
        <Table
            columns={columns}
            showSorterTooltip={false}
            dataSource={data}
            loading={isLoading || isCountingData}
            rowKey={(p: any) => p._key}
            scroll={{ x: minSmWidth }}
            pagination={{
                position: ['topRight', 'bottomRight'],
                current: pagination?.current || 1,
                showSizeChanger: false,
                total: pagination?.total || 0,
                pageSize: limit,
            }}
            onChange={(p, filters, s) => {
                setPagination(p);
                setSorter(s);
            }}
            {...{ expandable }}
        />
    );

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------

    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[TableList] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[TableList] destroy');

        // Destroy refresh interval instance
        clearInterval(dataRefreshIntervalInstance.current);
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to save initial data in originalData for not call get all times search was resetted
     */
    useEffect(() => {
        if (data.length && !originalData.length) {
            setOriginalData(data);
        }
    }, [data]);

    /**
     * Hook to pagination: check if is necessary recall get or show initial data after search, change page and sorter
     */
    useEffect(() => {
        if (pagination) {
            // If total items is 0 don't do get and show result
            if (pagination?.total === 0) {
                onLoadData([]);
            } else if (
                pagination?.current === originalPagination?.current &&
                pagination?.total === originalPagination?.total &&
                typeof search !== 'undefined' &&
                !sorter
            ) {
                onLoadData(originalData);
            }
        }
    }, [pagination, sorter]);

    /**
     * Hook to fetchedData in order to fire new fetched data callback
     */
    useEffect(() => {
        if (!!fetchedData && fetchedData.length > 0) onLoadData(fetchedData);
    }, [fetchedData]);

    /**
     * Hook to search in order to reset pagination to default settings if the search filter is removed
     */
    useEffect(() => {
        (async () => {
            if (!search) {
                setPagination(originalPagination);
            }
        })();
    }, [search]);

    /**
     * Hook to dataCount in order to set the default pagination settings
     */
    useEffect(() => {
        if (typeof dataCount !== 'undefined') {
            const defaultPagination = {
                current: 1,
                total: dataCount,
                pageSize: limit,
            };

            // Save current pagination in order to restore it after clearing search
            setOriginalPagination(defaultPagination);
            setPagination(defaultPagination);
        }
    }, [dataCount]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div className="tablelist">
            {loadMethod.filters?.length ? renderSearch() : null}
            <div style={{ position: 'relative' }}>
                <div className="table-button">
                    {onAddRow ? (
                        <Button
                            style={viewportWidth >= minSmWidth ? { marginTop: '16px' } : {}}
                            type="primary"
                            onClick={onAddRow}
                        >
                            {t('add_table', { elem: entityName })}
                        </Button>
                    ) : null}
                    {onDownload ? (
                        <Button
                            style={viewportWidth >= minSmWidth ? { marginTop: '16px' } : {}}
                            type="primary"
                            onClick={onDownload}
                        >
                            {
                                // @ts-ignore
                                t(downloadLabel)
                            }
                        </Button>
                    ) : null}
                </div>
                {minSmWidth > 0 && viewportWidth >= minSmWidth ? renderTable() : renderList()}
            </div>
        </div>
    );
};
export default TableList;
