import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import ExportDataView from './tabs/data';
import ExportQuoteView from './tabs/quote';

import Header from '../../../components/layout/header/header';
// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

const ExportView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ExportView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ExportView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <div className="main-card">
            <Header />
            <div className="export-container">
                <h1>{t('screens.private.export.title')}</h1>
                <p>{t('screens.private.export.subTitle')}</p>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={t('screens.private.export.tabs.data.title')} key="data">
                        <ExportDataView />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('screens.private.export.tabs.quote.title')} key="quote">
                        <ExportQuoteView />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};
export default ExportView;
