import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { History } from 'history';
import { AuthState } from '../../modules/authentication/context/auth/reducer';
import { ROUTES } from '../../config/routing/routes';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    history: History;
    renderPrivateLayout: any;
    privateCustomValidation?: any;
    children: ReactElement;
}

const PrivateLayout = ({
    history,
    privateCustomValidation,
    renderPrivateLayout,
    children,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [canProceed, setCanProceed] = useState<boolean>(false);

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------
    // Get data from the authReducer store state
    const { user } = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer);

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[PrivateLayout] init');

        console.log('REDIRECT', history);
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[PrivateLayout] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to user in order to handle authorization
     */
    useEffect(() => {
        if (!user) {
            setCanProceed(false);

            history.push({
                pathname: ROUTES.SIGN_IN,
                search: `?redirect_url=${encodeURIComponent(
                    history.location.pathname + history.location.search,
                )}`,
            });
        } else {
            if (privateCustomValidation) {
                privateCustomValidation();
            }
            setCanProceed(true);
        }
    }, [user]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return canProceed ? renderPrivateLayout(children, restProps) : null;
};
export default PrivateLayout;
