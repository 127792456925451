import { SIGN_IN_TYPES, SignInAction } from './types';
import { SIGN_OUT_TYPES } from '../signOut/types';

// -----------------------------------------------------------------
// I n i t i a l   S t a t e
// -----------------------------------------------------------------
const INITIAL_STATE = {
    errorMessage: null,
    confirm_token: null,
    pending: false,
    completed: false,
};

// -----------------------------------------------------------------
// S t a t e   I n t e r f a c e
// -----------------------------------------------------------------
export interface SignInState {
    errorMessage: string | null;
    confirm_token: string | null;
    pending: boolean;
    completed: boolean;
}

// -----------------------------------------------------------------
// A c t i o n   s w i t c h e r
// -----------------------------------------------------------------
export default (state: SignInState = INITIAL_STATE, action: SignInAction): SignInState => {
    switch (action.type) {
        case SIGN_IN_TYPES.PENDING:
            return {
                ...state,
                pending: true,
            };
        case SIGN_IN_TYPES.COMPLETED:
            return {
                ...state,
                errorMessage: null,
                confirm_token: null,
                pending: false,
                completed: true,
            };
        case SIGN_IN_TYPES.ADD_ERROR:
            return {
                ...state,
                errorMessage: action.payload.message,
                confirm_token: action.payload.response,
                pending: false,
            };
        case SIGN_IN_TYPES.CLEAR_ERROR:
            return {
                ...state,
                errorMessage: null,
                confirm_token: null,
                pending: false,
            };
        case SIGN_OUT_TYPES.COMPLETED:
            return {
                ...state,
                errorMessage: null,
                confirm_token: null,
                pending: false,
                completed: false,
            };
        default:
            return state;
    }
};
