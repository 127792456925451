import axios from 'axios';
import _environment from '../_environment/_environment';

export const baseUrl = _environment.api.baseURL;

export const httpClient = axios.create({
    baseURL: baseUrl,
});

// Alter defaults after instance has been created, setups a project wide header
// that is used to determine that the request is coming from the BackOffice
httpClient.defaults.headers.common['MRN-Special'] = 'c559fc6e-38e4-11ed-a261-0242ac120002';
