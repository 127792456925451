import { ReactElement, useEffect } from 'react';
import { History } from 'history';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { AuthState } from '../../modules/authentication/context/auth/reducer';
import { User } from '@marinelli/shared-types';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    history: History;
    children: ReactElement;
    alwaysVisible: boolean;
    renderPublicLayout: any;
    homeRoute: string;
}

const PublicLayout = ({
    history,
    renderPublicLayout,
    children,
    homeRoute,
    alwaysVisible,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------
    // Get data from the authReducer store state
    const { user } = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer);

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        console.log('[PublicLayout] init');
    };

    /**
     * Check if I have permission to visit this page or not
     *
     * @function
     * @param {User | undefined} u - User object
     * @returns {boolean}
     */
    const canIStayHere = (u: User | undefined): boolean => !u || alwaysVisible;

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        console.log('[PublicLayout] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to user in order to handle authorization
     */
    useEffect(() => {
        if (!canIStayHere(user)) {
            // Handle redirect url

            /* Returns an object of key/value pairs of URL parameters
         that you may use to access match.params of the current <Route> */
            const params = queryString.parse(history.location.search);

            if (!!params && !!params.redirect_url && typeof params.redirect_url === 'string') {
                history.push(params.redirect_url);
            } else {
                history.push({ pathname: homeRoute });
            }
        }
    }, [user]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return renderPublicLayout(children, restProps);
};
export default PublicLayout;
