/* eslint-disable global-require */
// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { Reseller } from '@marinelli/shared-types';
import { RcFile } from 'antd/lib/upload';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ENDPOINTS } from '../../data/enums';
import { IUploadForm } from '../../screens/private/import/tabs/upload';

/**
 * Imports the translations csv file
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IUploadForm} data - Form data
 * @returns {Promise<any>}
 */
export const importTranslations = async (data: IUploadForm): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append('file', data.file);

        const response: AxiosResponse = await httpClient({
            method: 'post',
            url: `${ENDPOINTS.TRANSLATIONS}/${data.lang}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};

/**
 * Imports the prices csv file
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IUploadForm} data - Form data
 * @returns {Promise<any>}
 */
export const importPrices = async (data: IUploadForm): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append('file', data.file);

        const response: AxiosResponse = await httpClient({
            method: 'post',
            url: `${ENDPOINTS.PRICES}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};

/**
 * Imports the prices csv file
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {"bases" | "configs"} entity - The DB table/entity to import
 * @param {IUploadForm} file - Form data
 * @returns {Promise<void>}
 */
export const importData = async (entity: 'bases' | 'configs', file: RcFile): Promise<void> => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        await httpClient({
            method: 'put',
            url: `${ENDPOINTS.IMPORT}/${entity}`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};

export const getReseller = async (id: string): Promise<Reseller | undefined> => {
    const res = await httpClient({ method: 'GET', url: `${ENDPOINTS.RESELLERS}?id=${id}` });
    return res.data;
};

export const getAllResellers = async (): Promise<string[]> => {
    const res = await httpClient({ method: 'GET', url: `${ENDPOINTS.RESELLERS}` });
    return res.data;
};

export const upsertReseller = async (reseller: Reseller): Promise<void> => {
    const res = await httpClient({
        method: 'PUT',
        url: `${ENDPOINTS.RESELLERS}/${reseller.id}`,
        data: reseller,
    });
    return res.data;
};

export const deleteReseller = async (resellerId: string): Promise<void> => {
    await httpClient({
        method: 'DELETE',
        url: `${ENDPOINTS.RESELLERS}/${resellerId}`,
    });
};

export const getAllTranslations = async (): Promise<string[]> => {
    const res = await httpClient({ method: 'GET', url: `${ENDPOINTS.TRANSLATIONS}` });
    return res.data;
};

export const getAssetsTree = async (): Promise<any> => {
    const res = await httpClient({ method: 'GET', url: `${ENDPOINTS.STATIC_TREE}` });
    return res.data;
};

export const upsertAsset = async (path: string, file: RcFile): Promise<void> => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        await httpClient({
            method: 'put',
            url: `${ENDPOINTS.STATIC}/${path}`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
        });
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};
