import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import _environment from './_environment/_environment';
import * as serviceWorker from './serviceWorker';
import App from './App';

// Define a new console function
const console = (function (oldCons: Console): Console {
    return {
        ...oldCons,
        log(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.log.apply(oldCons, args);
            }
        },
        info(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.info.apply(oldCons, args);
            }
        },
        warn(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.warn.apply(oldCons, args);
            }
        },
        error(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.error.apply(oldCons, args);
            }
        },
        trace(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.trace.apply(oldCons, args);
            }
        },
    };
})(window.console);

// Then redefine the old console
window.console = console;

const resources: { [key: string]: { translation?: string } } = {};

_environment.i18n.languages.forEach((lan) => {
    resources[lan] = {};
    resources[lan].translation = require(`./locales/${lan}/translation.json`);
});

// if (_environment.api.baseURL === 'http://localhost:3030') {
//     const { worker } = require('./mocks/browser');
//     worker.start();
// }

// Sentry tracking monitor
Sentry.init({
    dsn: _environment.sentry.dsn,
    enabled: _environment.sentry.enabled,
    environment: process.env.REACT_APP_ENV,
    release: _environment.version,
});

// Multilanguage setup
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: _environment.i18n.fallbackLanguage,
        resources,
        debug: process.env.REACT_APP_ENV === 'development',
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
    .then(() => {
        ReactDOM.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>,
            document.getElementById('root'),
        );
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
