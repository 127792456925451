import { Button, Card } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../../components/layout/header/header';
import { ROUTES } from '../../config/routing/routes';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------
interface Operation {
    title: string;
    description: string;
    button: {
        label: string;
        onClick: () => void;
    };
}

interface Props {}

const HomeView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Access to the history instance that you may use to navigate.
    const history = useHistory();

    const operations: Array<Operation> = [
        {
            title: t('screens.private.home.exportDataCard.title'),
            description: t('screens.private.home.exportDataCard.description'),
            button: {
                label: t('screens.private.home.exportDataCard.button'),
                onClick: () => {
                    history.push(ROUTES.EXPORT);
                },
            },
        },
        {
            title: t('screens.private.home.modifyDataCard.title'),
            description: t('screens.private.home.modifyDataCard.description'),
            button: {
                label: t('screens.private.home.modifyDataCard.button'),
                onClick: () => {
                    history.push(ROUTES.IMPORT);
                },
            },
        },
        {
            title: t('screens.private.home.handleErrorsCard.title'),
            description: t('screens.private.home.handleErrorsCard.description'),
            button: {
                label: t('screens.private.home.handleErrorsCard.button'),
                onClick: () => {
                    history.push(ROUTES.ERROR);
                },
            },
        },
    ];

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[HomeView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[HomeView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <div className="main-card">
            <Header />
            <div className="home-container">
                <h2 className="home-title">{t('screens.private.home.title')}</h2>
                <div className="card-container">
                    {operations.map((op: Operation) => (
                        <Card>
                            <h2>{op.title}</h2>
                            <p>{op.description}</p>
                            <Button type="primary" onClick={op.button.onClick}>
                                <h6>{op.button.label}</h6>
                            </Button>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default HomeView;
