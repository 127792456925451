import { SIGN_OUT_TYPES } from '../signOut/types';
import { SIGN_IN_TYPES, SignInAction } from '../signIn/types';
import { User } from '../../../common/data/interfaces';

// -----------------------------------------------------------------
// I n i t i a l   S t a t e
// -----------------------------------------------------------------
const INITIAL_STATE = {
    user: undefined,
};

// -----------------------------------------------------------------
// S t a t e   I n t e r f a c e
// -----------------------------------------------------------------
export interface AuthState {
    user: User | undefined;
}

// -----------------------------------------------------------------
// A c t i o n   s w i t c h e r
// -----------------------------------------------------------------
export default (state: AuthState = INITIAL_STATE, action: SignInAction | any): AuthState => {
    switch (action.type) {
        case SIGN_IN_TYPES.UPDATE_USER:
        case SIGN_IN_TYPES.COMPLETED:
            return JSON.parse(
                JSON.stringify({
                    ...state,
                    user: action.payload,
                }),
            );
        case SIGN_OUT_TYPES.COMPLETED:
            return {
                ...state,
                user: undefined,
            };
        default:
            return state;
    }
};
