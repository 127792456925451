import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ENDPOINTS } from '../../data/enums';

// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------

/**
 * Returns the list of resellers
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Reseller
 */
export const getResellers = async (): Promise<Array<string>> => {
    try {
        const response: AxiosResponse = await httpClient.get(`${ENDPOINTS.RESELLERS}`);

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};
