import React, { ReactElement, useEffect } from 'react';
import { History } from 'history';
import { ConfigProvider } from 'antd';
import useLanguage from './hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import PublicLayout from './routing/layout/PublicLayout';
import Routes from './routing/Routes';
import NotFoundView from './screens/public/notFound';
import useRoutes from './hooks/useRoutes';
import 'remixicon/fonts/remixicon.css';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    history: History;
}

const Main = ({ history, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    const { i18n } = useTranslation();

    // Access to antd locales manager hook.
    const { getLocaleByIsoCode, getIso2ByLang } = useLanguage();

    const currentLanguage: string = getIso2ByLang(i18n.language);

    // Access to routes manager hook.
    const { getHomeRoute } = useRoutes();

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    // const [showCookieBar, setShowCookieBar] = useState<boolean>(false);

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render public layout node
     *
     * @param {any} children - Component representing route content
     * @param {any} data - Input parameters of the route content component
     * @function
     * @returns {ReactElement | null}
     */
    const renderPublicLayout = (children: ReactElement, data: any): ReactElement | null => (
        <div id="public-layout">
            <div>{React.cloneElement(children, data)}</div>
        </div>
    );

    /**
     * Render private layout node
     *
     * @param {any} children - Component representing route content
     * @param {any} data - Input parameters of the route content component
     * @function
     * @returns {ReactElement | null}
     */
    const renderPrivateLayout = (children: ReactElement, data: any): ReactElement | null => (
        <div id="private-layout">
            <div>{React.cloneElement(children, data)}</div>
        </div>
    );

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Main] init');

        // if (!Cookies.get(COOKIES.COOKIES_ACCEPTED)) {
        //     setShowCookieBar(true);
        // }
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Main] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    return (
        <ConfigProvider locale={getLocaleByIsoCode(currentLanguage)}>
            {/* @ts-ignore */}
            <Routes
                history={history}
                renderPrivateLayout={renderPrivateLayout}
                renderPublicLayout={renderPublicLayout}
                homeRoute={getHomeRoute()}
                notFound={{
                    layout: PublicLayout,
                    view: NotFoundView,
                }}
            />

            {/* Scroll to Top */}
            {/* {<BackTop>
                <i className="ri-arrow-up-s-line" />
            </BackTop>} */}

            {/* Cookie bar */}
            {/* {showCookieBar && <CookieBar onClose={() => setShowCookieBar(false)} />} */}
        </ConfigProvider>
    );
};

export default Main;
