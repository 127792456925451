import axios, { CancelTokenStatic } from 'axios';
import _environment from '../_environment/_environment';

export const axiosApiInstance = axios.create({
    baseURL: _environment.api.baseURL,
    responseType: 'json',
});

// let signal: any = axios.CancelToken.source();
const cancelToken: CancelTokenStatic = axios.CancelToken;

const interceptor = (): void => {
    // Request interceptor for API calls

    axiosApiInstance.interceptors.request.use(
        async (config: any) => {
            // config.cancelToken = cancelToken.source().token;

            config.headers = {
                ...config.headers,
                ...{ Accept: 'application/json' },
            };

            let configProp: string = '';

            // Set the config object property according to the http request type
            switch (config.method) {
                case 'post':
                    configProp = 'data';
                    break;
                case 'delete':
                    break;
                case 'put':
                    break;
                case 'patch':
                    break;
                case 'get':
                default:
                    configProp = 'params';
                    break;
            }

            // If it's a form submit call, construct the formData object (if you pass it as a formdata object it will doesn't work)
            if (config.headers['Content-Type'] === 'multipart/form-data') {
                const formdata = new FormData();

                // Add any properties of the past object to the formdata object
                Object.keys(config[configProp]).forEach((key) => {
                    if (Object.prototype.hasOwnProperty.call(config[configProp], key)) {
                        formdata.append(key, config[configProp][key]);
                    }
                });

                // Update data
                config[configProp] = formdata;
            }

            // Returning the configuration parameters
            return config;
        },
        (error) => Promise.reject(error),
    );

    // Response interceptor for API calls
    axiosApiInstance.interceptors.response.use(
        (next) => Promise.resolve(next),
        async (err) => {
            // Server is unreachable
            if (!err.response) {
                return Promise.reject('errors.server_unreachable');
            }

            return Promise.reject(`errors.${err.response.data.error}`);
        },
    );
};

// export { areRequestsCanceled, cancelRequests };
export { cancelToken };
export default interceptor;
