import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../data/enums';
import { getResellers } from '../../redux/resellers/action';
import useQueries from '../useQueries';

/**
 * @param {boolean} enabled - Determine if query should be enabled
 * @returns {UseQueryResult<any, any>}
 */
export const useGetResellersQuery = (
    enabled: boolean = false,
): UseQueryResult<Array<string>, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_RESELLERS],
        async () => {
            return getResellers();
        },
        getFetchRequestQueryParams({ enabled }),
    );
};
