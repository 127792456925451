export enum ENDPOINTS {
    SIGN_IN = '/auth/login',
    SIGN_UP = '/api/v1/users',
    RESEND_CONFIRMATION_EMAIL = '/api/v1/users/account/verify/send',
    VALIDATE_CONFIRM_TOKEN = '/api/v1/users/account/verify',
    REFRESH_ACCESS_TOKEN = '/api/v1/users/login/refresh',
    FORGOT_PASSWORD = '/api/v1/users/password/reset/send',
    RESET_PASSWORD = '/api/v1/users/password/reset',
    REMOVE_USER = '/api/v1/auth/removeUser',
}
