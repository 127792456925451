/**
 * COMMENT ME
 *
 * @enum {string}
 * @alias Queries Keys
 * @category Enum
 */
export enum QUERIES_KEYS {
    EXPORT_QUOTE = 'exportQuote',
    EXPORT_DATA = 'exportData',
    GET_LANGUAGES = 'getLanguages',
    GET_ERRORS = 'getErrors',
    GET_RESELLERS = 'getResellers',
    GET_LANGUAGES_CSV_TEMPLATE = 'getLanguagesCsvTemplate',
    GET_PRICES_CSV_TEMPLATE = 'getPricesCsvTemplate',
}

/**
 * COMMENT ME
 *
 * @enum {string}
 * @alias Endpoints
 * @category Enum
 */
export enum ENDPOINTS {
    ORDERS = 'orders',
    TRANSLATIONS = 'env/translations',
    RESELLERS = 'env/resellers',
    STATIC = 'static',
    STATIC_TREE = 'static-tree',
    PRICES = 'prices',
    IMPORT = 'data',
    ERRORS = 'logs',
}

/**
 * Default params for some route
 *
 * @enum {string}
 * @alias Route Default Params
 * @category Enum
 */
export enum ROUTE_DEFAULT_PARAMS {
    NEW_RECORD = 'new',
}
