import { SignOutAction } from '../signOut/types';
import { User } from '../../../common/data/interfaces';

export enum SIGN_IN_TYPES {
    PENDING = 'SIGN_IN_PENDING',
    COMPLETED = 'SIGN_IN_COMPLETED',
    UPDATE_USER = 'SIGN_IN_UPDATE_USER',
    ADD_ERROR = 'SIGN_IN_ADD_ERROR',
    CLEAR_ERROR = 'SIGN_IN_CLEAR_ERROR',
}

interface Pending {
    type: SIGN_IN_TYPES.PENDING;
}

interface Completed {
    type: SIGN_IN_TYPES.COMPLETED;
    payload: Partial<User>;
}

interface UpdateUser {
    type: SIGN_IN_TYPES.UPDATE_USER;
    payload: Partial<User>;
}

interface AddError {
    type: SIGN_IN_TYPES.ADD_ERROR;
    payload: {
        message: string;
        response: string;
    };
}

interface ClearError {
    type: SIGN_IN_TYPES.CLEAR_ERROR;
}

export type SignInAction = Pending | Completed | UpdateUser | AddError | ClearError | SignOutAction;
