import { Button } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signOut } from '../../../modules/authentication/context/action';
import { AuthState } from '../../../modules/authentication/context/auth/reducer';
import Breadcrumbs from '../breadcrumbs';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

const Header = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------
    // Get data from the authReducer store state
    const { user } = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer);

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Reference to Redux store dispatch function: use it to dispatch actions.
    const dispatch = useDispatch();

    // Access to the history instance that you may use to navigate.
    const history = useHistory();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Header] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Header] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <>
            <div className="header-container">
                <div className="header-content">
                    <p>
                        {t('components.header.text', {
                            username: `${user?.firstname} ${user?.lastname}`,
                        })}
                    </p>
                    <Button type="link" onClick={() => dispatch(signOut(history))}>
                        {t('components.header.buttons.logOut')}
                    </Button>
                </div>
                {/* Breadcrumbs */}
                <Breadcrumbs />
            </div>
        </>
    );
};
export default Header;
