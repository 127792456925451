import { Button, Col, Form, notification, Row, Spin, TreeSelect } from 'antd';
import { TreeNode } from 'antd/lib/tree-select';
import Upload, { RcFile } from 'antd/lib/upload';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAssetsTree } from '../../../../redux/import/action';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    onUpsert: (path: string, file: RcFile) => Promise<void>;
}

type Tree = Record<string, { path: string; children: Tree } | string>;

const AssetsForm = ({ onUpsert, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    // Shows spinner when tree fetch o asset update are performing
    const [isLoading, setLoading] = useState<boolean>(false);
    // Keep tracks of the filesystem tree to be rendered in <TreeSelect /> components
    const [assetTree, setAssetTree] = useState<Tree>({});

    // Keeps tracks of the uploaded file
    const [file, setFile] = useState<RcFile | undefined>(undefined);
    // Keeps tracks of the path to be updated
    const [path, setPath] = useState<string>('');

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    const handleSubmit = async (): Promise<void> => {
        try {
            if (!!file) await onUpsert(path, file);
            // Print success to the user
            notification.success({
                message: t('success.general.title'),
                description: t('success.import_completed'),
            });
        } catch (exception) {
            notification.error({
                message: t('errors.general.title'),
                description: t('errors.general.description'),
            });
        } finally {
            setLoading(false);
        }
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------
    const renderTree = (node: Tree): ReactNode => {
        return Object.entries(node).map(([name, subnode]): ReactNode => {
            if (typeof subnode === 'object')
                return (
                    <TreeNode key={subnode.path} value={subnode.path} title={name}>
                        {renderTree(subnode.children)}
                    </TreeNode>
                );

            if (typeof subnode === 'string')
                return <TreeNode key={subnode} value={subnode} title={name} />;

            return undefined;
        });
    };

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        setLoading(true);
        // Fetches onMount the assets/filesystem tree for the server
        getAssetsTree()
            .then((data) => setAssetTree(data))
            .catch(console.error)
            .finally(() => setLoading(false));

        // init component
        console.log('[ResellerForm] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ResellerForm] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <Row>
            <Col xs={12} className="text-container">
                <h1>{t('screens.private.import.tabs.assets.title')}</h1>
                <p className="preline">{t(`screens.private.import.tabs.assets.subTitle`)}</p>
            </Col>

            <Col xs={12}>
                <Spin spinning={isLoading}>
                    <div className="bottom-container">
                        <Form.Item>
                            <TreeSelect
                                showSearch
                                onChange={setPath}
                                placeholder="Seleziona il path in cui caricare il file"
                                treeLine={{ showLeafIcon: true }}
                            >
                                {renderTree(assetTree)}
                            </TreeSelect>
                        </Form.Item>

                        <Form.Item>
                            <Upload.Dragger
                                name="file"
                                accept=".png"
                                maxCount={1}
                                multiple={false}
                                customRequest={() => {}}
                                fileList={!!file ? [file] : undefined}
                                onRemove={() => setFile(undefined)}
                                onChange={(info) => setFile(info.file.originFileObj)}
                            >
                                <p className="drag-icon">
                                    <i className="ri-upload-2-fill" />
                                </p>
                                <h6>
                                    {t('screens.private.import.upload.title', { format: '.png' })}
                                </h6>
                                <p>{t('screens.private.import.upload.description')}</p>
                            </Upload.Dragger>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!file || !path}
                                onClick={handleSubmit}
                            >
                                {t(`screens.private.import.tabs.resellers.bottom.button`)}
                            </Button>
                        </Form.Item>
                    </div>
                </Spin>
            </Col>
        </Row>
    );
};
export default AssetsForm;
