import axios, { CancelTokenStatic } from 'axios';
import _environment from '../../../_environment/_environment';
import { signOut } from '../context/action';
import { JWT } from '../data/interfaces';
import { getLocalStorageJWT } from '../utils/utils';

export const axiosApiInstance = axios.create({
    baseURL: _environment.api.baseURL,
    responseType: 'json',
});

const cancelToken: CancelTokenStatic = axios.CancelToken;

const interceptor = (store: any): void => {
    // Request interceptor for API calls

    axiosApiInstance.interceptors.request.use(
        async (config: any) => {
            // config.cancelToken = cancelToken.source().token;

            config.headers = {
                ...config.headers,
                ...{ Accept: 'application/json' },
            };

            let configProp = '';

            // Set the config object property according to the http request type
            switch (config.method) {
                case 'post':
                    configProp = 'data';
                    break;
                case 'delete':
                    break;
                case 'put':
                    break;
                case 'patch':
                    break;
                case 'get':
                default:
                    configProp = 'params';
                    break;
            }

            // If it's a form submit call, construct the formData object (if you pass it as a formdata object it will doesn't work)
            if (config.headers['Content-Type'] === 'multipart/form-data') {
                const formdata = new FormData();

                // Add any properties of the past object to the formdata object
                Object.keys(config[configProp]).forEach((key) => {
                    if (Object.prototype.hasOwnProperty.call(config[configProp], key)) {
                        formdata.append(key, config[configProp][key]);
                    }
                });

                // Update data
                config[configProp] = formdata;
            }

            // If the user is logged in, add the access_token to the formdata object
            const localStorageJWT: JWT | undefined = getLocalStorageJWT();

            if (localStorageJWT) {
                // Set the exec token in each http request
                config.headers.Authorization = `Bearer ${localStorageJWT.access_token}`;
            }

            // Returning the configuration parameters
            return config;
        },
        (error) => Promise.reject(error),
    );

    // Response interceptor for API calls
    axiosApiInstance.interceptors.response.use(
        (next) => Promise.resolve(next),
        async (err) => {
            // const originalRequest = err.config;

            // Server is unreachable
            if (!err.response) {
                return Promise.reject('errors.server_unreachable');
            }

            if (err.response.status === 401) {
                switch (err.response.data.error) {
                    /* case 'access_token_expired':

                      // You are trying to make a notarized request without permission
                      const localStorageJWT: any = getLocalStorageJWT();

                      // If the user is not logged in, return error
                      if (!localStorageJWT) throw { message: getResponseErrorMessage(err) };

                      // Request a new access token by passing the refresh token
                      const newJWT: JWT = await refreshAccessToken(localStorageJWT.refresh_token);

                      // Overwrite the access token with the new one
                      localStorageJWT.access_token = newJWT.access_token;

                      // Updating the jwt object in local storage
                      localStorage.setItem(LOCAL_STORAGE.JWT, JSON.stringify(localStorageJWT));

                      let params: any;

                      if (originalRequest.params) {
                          if (typeof originalRequest.params === 'string') {
                              params = JSON.parse(originalRequest.params);
                          } else {
                              params = originalRequest.params;
                          }
                      } else {
                          params = undefined;
                      }

                      // Repeat http request with the new access token
                      return axiosApiInstance(
                          `${_environment.api.baseURL}${originalRequest.url}`,
                          {
                              params,
                              data: originalRequest.data
                                  ? JSON.parse(originalRequest.data)
                                  : undefined,
                              method: originalRequest.method,
                          },
                      );

                      */

                    case 'refresh_token_expired':
                        try {
                            // Refresh token has expired: signout
                            store.dispatch(signOut());

                            // Return the error
                            throw err.response.data.error;
                        } catch (e) {
                            return Promise.reject(e);
                        }
                    default:
                        if (err.response.data.response) {
                            // Return the error
                            return Promise.reject({
                                response: {
                                    data: {
                                        error: err.response.data.error,
                                        response: err.response.data.response,
                                    },
                                },
                            });
                        }
                        // Unhandled error: return it in default mode
                        return Promise.reject(err.response.data.error);
                }
            } else {
                return Promise.reject(err.response.data.error);
            }
        },
    );
};

export { cancelToken };
export default interceptor;
