import { ROUTE_DEFAULT_PARAMS } from '../data/enums';

/**
 * Checks whether the detail route is creation or modification by reading the parameter that uniquely identifies the record key
 *
 * @function
 * @category Utils
 * @class
 * @subcategory Url
 * @param {string | undefined} val - Parameter value read from query string
 */
export const isNewEntityDetailView = (val: string | undefined): boolean =>
    typeof val !== 'undefined' ? val === ROUTE_DEFAULT_PARAMS.NEW_RECORD : false;
