import { SIGN_OUT_TYPES, SignOutAction } from './types';

// -----------------------------------------------------------------
// I n i t i a l   S t a t e
// -----------------------------------------------------------------
const INITIAL_STATE = {
    errorMessage: null,
    pending: false,
};

// -----------------------------------------------------------------
// S t a t e   I n t e r f a c e
// -----------------------------------------------------------------
export interface SignOutState {
    errorMessage: string | null;
    pending: boolean;
}

// -----------------------------------------------------------------
// A c t i o n   s w i t c h e r
// -----------------------------------------------------------------
export default (state: SignOutState = INITIAL_STATE, action: SignOutAction): SignOutState => {
    switch (action.type) {
        case SIGN_OUT_TYPES.PENDING:
            return {
                ...state,
                pending: true,
            };
        case SIGN_OUT_TYPES.COMPLETED:
            return {
                ...state,
                errorMessage: null,
                pending: false,
            };
        case SIGN_OUT_TYPES.ADD_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                pending: false,
            };
        case SIGN_OUT_TYPES.CLEAR_ERROR:
            return {
                ...state,
                errorMessage: null,
                pending: false,
            };
        default:
            return state;
    }
};
