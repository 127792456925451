import React, { ReactElement, useEffect } from 'react';
import useBreadcrumbs, { BreadcrumbsRoute, Options } from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import { isNewEntityDetailView } from '../../utils/url';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    routes?: Array<BreadcrumbsRoute>;
    options?: Options;
}

/**
 * Component that renders the visitor's navigation breadcrumbs
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {Array<BreadcrumbsRoute>} anonymousObject.routes - Routes to which to apply business logic in rendering
 * @param {Options} anonymousObject.options - Rendering options (e.g. excluded routes, etc.)
 * @component
 * @category Components
 * @subcategory Fragment
 * @returns {ReactElement | null}
 */
const Breadcrumbs = ({ routes, options, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to navigation breadcrumbs
    const breadcrumbs = useBreadcrumbs(routes, options);

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    /* Returns an object of key/value pairs of URL parameters
  that you may use to access match.params of the current <Route> */
    const params: any = useParams();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Manages the printing of the label, checking if it is a parameterised value
     *
     * @function
     * @param {any} bd - A BredCrumb object (?)
     * @returns {string}
     */
    const getBreadcrumbLabel = (bd: any): string => {
        // This variable checks whether the url contains parameters (placeholder variables).
        const hasParam: boolean = Object.keys(bd.match.params).length > 0;

        // This variable contains the name of the current page
        const label: string = bd.breadcrumb.props.children;

        // Get the last placeholder variable (if any), used as the entity key in the case of a detail view
        const urlKey: Array<string> | null = /[^:]*$/.exec(bd.match.path);

        // If the url contains parameters (placeholder variables) and is not
        // a submission of a new entity, show the value of this variable.
        // This will be the corresponding value of a property of an entity.
        if (hasParam && urlKey && !isNewEntityDetailView(params[urlKey[0]])) {
            return label;
        }
        // Otherwise show the corresponding translation.
        // This will be the name of a page without parameters.
        return t(`breadcrumbs.${label || 'Home'}`);
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Breadcrumbs] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Breadcrumbs] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <Breadcrumb>
            {breadcrumbs.map((bd: any, i) => (
                <Breadcrumb.Item>
                    {i !== breadcrumbs.length - 1 ? (
                        <NavLink to={bd.match.url}>{getBreadcrumbLabel(bd)}</NavLink>
                    ) : (
                        <span>{getBreadcrumbLabel(bd)}</span>
                    )}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};
export default Breadcrumbs;
