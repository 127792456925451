import React, { ReactElement } from 'react';
import './App.less';
import { createBrowserHistory, History } from 'history';
import 'antd/dist/antd.less';
import './styles/style.scss';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { BrowserCompatibilityContextProvider } from './modules/browser-compatibility/browserCompatibility';
import 'moment/min/locales';
import { PersistGate } from 'redux-persist/integration/react';
import Main from './Main';
import { persistor, store } from './config/store';
import _environment from './_environment/_environment';
import { NotificationContextProvider } from './providers/notification';
import { ModalContextProvider } from './providers/modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// interceptor(store);

/**
 * Main React component
 *
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
function App(): ReactElement | null {
    const history: History = createBrowserHistory();

    // Create a client
    const queryClient = new QueryClient();

    console.warn('Hello from new MRN Server');

    return (
        <div className="marinelli-app">
            <QueryClientProvider client={queryClient}>
                <BrowserCompatibilityContextProvider _environment={_environment}>
                    <Router history={history}>
                        <NotificationContextProvider>
                            <Provider store={store}>
                                <PersistGate loading={null} persistor={persistor}>
                                    <ModalContextProvider>
                                        <Main history={history} />
                                    </ModalContextProvider>
                                </PersistGate>
                            </Provider>
                        </NotificationContextProvider>
                    </Router>
                </BrowserCompatibilityContextProvider>
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
        </div>
    );
}

export default App;
