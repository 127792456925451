import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../data/enums';
import { getLanguages } from '../../redux/languages/action';
import useQueries from '../useQueries';

/**
 * @returns {UseQueryResult<any, any>}
 */
export const useGetLanguagesQuery = (): UseQueryResult<Array<string>, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_LANGUAGES],
        async () => {
            return getLanguages();
        },
        getFetchRequestQueryParams(),
    );
};
