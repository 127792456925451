import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Select, notification, Upload, Spin, Input, Space, Row, Col } from 'antd';
import moment from 'moment';
import useQueries from '../../../../hooks/useQueries';
import { useMutation } from 'react-query';
import { useGetLanguagesQuery } from '../../../../hooks/react-query/useGetLanguages';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useGetLanguagesCsvTemplateQuery } from '../../../../hooks/react-query/useGetLanguagesCsvTemplate';
import download from 'downloadjs';
import { useGetPricesCsvTemplateQuery } from '../../../../hooks/react-query/useGetPricesCsvTemplate';
import { useGetResellersQuery } from '../../../../hooks/react-query/useGetResellers';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    onUpload: (data: IUploadForm) => any;
    exportType: EXPORT_TYPES;
}

export interface IUploadForm {
    file: any;
    lang?: string;
    reseller?: string;
}

/**
 * COMMENT ME
 *
 * @enum {string}
 * @alias ExportTypes
 * @category Enum
 */
export enum EXPORT_TYPES {
    TRANSLATIONS = 'translations',
    PRICES = 'prices',
}

const UploadCsv = ({ onUpload, exportType, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [lastUpdate, setLastUpdate] = useState<string>(moment().toISOString());

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const { handleSubmit, control, formState, watch } = useForm<IUploadForm>({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object().shape({
                file: yup
                    .mixed()
                    .required(`screens.private.import.${exportType}.form.file_require`),
                lang:
                    exportType === EXPORT_TYPES.TRANSLATIONS
                        ? yup
                              .string()
                              .required(
                                  t(`screens.private.import.tabs.${exportType}.form.lang_require`),
                              )
                        : yup.string().nullable(),
                reseller: yup.string().nullable(),
            }),
        ),
    });

    const watchLang = watch('lang');
    const watchReseller = watch('reseller');

    const { getUpsertRequestQueryParams }: any = useQueries();

    // Subscription to a fetch query
    const { data: availableLanguages, isLoading: isFetchingLanguages } = useGetLanguagesQuery();

    // Subscription to a fetch query
    const { data: availableResellers, isLoading: isFetchingResellers } = useGetResellersQuery(
        exportType === EXPORT_TYPES.PRICES,
    );

    // Subscription to a fetch query
    const { refetch: getLanguagesCsvTemplate, isLoading: isFetchingLanguagesCsvTemplate } =
        useGetLanguagesCsvTemplateQuery(watchLang || '', (responseData: any) => {
            downloadTemplateFile(responseData);
        });

    // Subscription to a fetch query
    const { refetch: getPricesCsvTemplate, isLoading: isFetchingPricesCsvTemplate } =
        useGetPricesCsvTemplateQuery(watchLang, watchReseller, (responseData: any) => {
            downloadTemplateFile(responseData);
        });

    // Subscription to an upsert query
    const { mutate: triggerUploadFile, isLoading: isUploadingFile } = useMutation(
        (data: IUploadForm) => onUpload(data),
        {
            ...getUpsertRequestQueryParams(),
            ...{
                onSuccess: () => {
                    setLastUpdate(moment().toISOString());

                    // Print success to the user
                    notification.success({
                        message: t('success.general.title'),
                        description: t('success.import_completed'),
                    });
                },
            },
        },
    );

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Handle submit form
     *
     * @function
     * @param {MaxLengthFormData} formData - Form data
     * @returns {void}
     */
    const handleSubmitForm = (formData: IUploadForm): void => {
        triggerUploadFile(formData);
    };

    /**
     * Download File data, obtained from response
     *
     * @function
     * @param {any} responseData - File data to download, obtained from response
     * @returns {void}
     */
    const downloadTemplateFile = (responseData: any): void => {
        let fileName: string = exportType;
        if (!!watchReseller) fileName += `-${watchReseller}`;
        if (!!watchLang) fileName += `-${watchLang}`;
        fileName += '.csv';
        download(responseData, fileName.toLowerCase());
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[UploadCsv] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[UploadCsv] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <Row>
            <Col xs={12}>
                <div className="text-container">
                    <h1>{t(`screens.private.import.tabs.${exportType}.title`)}</h1>
                    <p className="preline">
                        {t(`screens.private.import.tabs.${exportType}.subTitle`)}
                    </p>
                </div>
            </Col>

            <Col xs={12}>
                <Form
                    key={lastUpdate}
                    layout="vertical"
                    size="large"
                    onFinish={handleSubmit(handleSubmitForm)}
                >
                    <Row justify="center">
                        {exportType === EXPORT_TYPES.PRICES && (
                            <Col xs={8}>
                                {isFetchingResellers ? (
                                    <Spin />
                                ) : (
                                    <Form.Item
                                        label={t(
                                            `screens.private.import.tabs.${exportType}.reseller.title`,
                                        )}
                                    >
                                        <Controller
                                            name="reseller"
                                            control={control}
                                            render={({ onChange, onBlur, value }) => (
                                                <Select
                                                    showArrow
                                                    placeholder={t(
                                                        `screens.private.import.tabs.${exportType}.reseller.placeholder`,
                                                    )}
                                                    style={{ width: 200 }}
                                                    disabled={isUploadingFile}
                                                    value={value}
                                                    onChange={(v: string) => {
                                                        onChange(v);
                                                    }}
                                                    onBlur={onBlur}
                                                >
                                                    {availableResellers?.map((reseller: string) => (
                                                        <Select.Option value={reseller}>
                                                            {reseller}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        )}

                        <Col xs={exportType === EXPORT_TYPES.TRANSLATIONS ? 16 : 8}>
                            {isFetchingLanguages ? (
                                <Spin />
                            ) : (
                                <Form.Item
                                    label={t(
                                        `screens.private.import.tabs.${exportType}.language.title`,
                                    )}
                                >
                                    <Controller
                                        name="lang"
                                        control={control}
                                        render={({ onChange, onBlur, value }) => (
                                            <Row justify="space-around">
                                                <Col
                                                    xs={
                                                        exportType === EXPORT_TYPES.TRANSLATIONS
                                                            ? 12
                                                            : 24
                                                    }
                                                >
                                                    <Select
                                                        showArrow
                                                        placeholder="Codice paese esistente"
                                                        style={{ width: 200 }}
                                                        disabled={isUploadingFile}
                                                        value={value}
                                                        onChange={(v: string) => onChange(v)}
                                                        onBlur={onBlur}
                                                        // disabled={isFormSubmitting} // TODO: UTILIZZARE ISLOADING DI REACT-QUERY
                                                    >
                                                        {availableLanguages?.map((lang: string) => (
                                                            <Select.Option value={lang}>
                                                                {lang}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                {exportType === EXPORT_TYPES.TRANSLATIONS ? (
                                                    <Col xs={12}>
                                                        <Space>
                                                            <Input
                                                                value={value}
                                                                onChange={onChange}
                                                                placeholder="Nuovo codice paese"
                                                            />
                                                        </Space>
                                                    </Col>
                                                ) : undefined}
                                            </Row>
                                        )}
                                    />
                                </Form.Item>
                            )}
                        </Col>

                        <Col xs={8}>
                            <Form.Item
                                label={t(
                                    `screens.private.import.tabs.${exportType}.exportTemplateButton`,
                                )}
                            >
                                <Button
                                    type="primary"
                                    disabled={
                                        exportType === EXPORT_TYPES.TRANSLATIONS && !watchLang
                                    }
                                    onClick={() => {
                                        if (exportType === EXPORT_TYPES.TRANSLATIONS) {
                                            getLanguagesCsvTemplate();
                                        } else {
                                            getPricesCsvTemplate();
                                        }
                                    }}
                                    loading={
                                        isFetchingLanguagesCsvTemplate ||
                                        isFetchingPricesCsvTemplate
                                    }
                                >
                                    {t(
                                        `screens.private.import.tabs.${exportType}.exportTemplateButton`,
                                    )}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row justify="center">
                        <Form.Item validateStatus={!!formState.errors.file ? 'error' : ''}>
                            <Controller
                                name="file"
                                control={control}
                                render={({ onChange }) => (
                                    <Upload.Dragger
                                        accept=".csv"
                                        maxCount={1}
                                        onRemove={() => {
                                            setTimeout(() => {
                                                onChange(null);
                                            }, 0);
                                        }}
                                        multiple={false}
                                        name="file"
                                        customRequest={(opt: UploadRequestOption) => {
                                            if (!opt.onSuccess) return;
                                            opt.onSuccess('');
                                        }}
                                        onChange={(info) => {
                                            onChange(info.file.originFileObj);
                                        }}
                                    >
                                        <p className="drag-icon">
                                            <i className="ri-upload-2-fill" />
                                        </p>
                                        <h6>
                                            {t('screens.private.import.upload.title', {
                                                format: '.csv',
                                            })}
                                        </h6>
                                        <p>{t('screens.private.import.upload.description')}</p>
                                    </Upload.Dragger>
                                )}
                            />
                        </Form.Item>
                    </Row>

                    <Row justify="center">
                        <Form.Item>
                            <Button
                                type="primary"
                                disabled={!formState.isValid}
                                loading={isUploadingFile}
                                htmlType="submit"
                            >
                                {t(`screens.private.import.tabs.${exportType}.bottom.button`)}{' '}
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};
export default UploadCsv;
