import { Dispatch } from 'react';
import { User } from '../../common/data/interfaces';
import { SIGN_IN_TYPES, SignInAction } from './signIn/types';
import { SIGN_OUT_TYPES, SignOutAction } from './signOut/types';
import { History } from 'history';
import { ROUTES } from '../../../config/routing/routes';
import { axiosApiInstance } from '../api/apiService';
import { ENDPOINTS } from '../data/endpoints';
import { getResponseErrorMessage } from '../../../utils/utils';
import { getLocalStorageJWT, updateAuthUserInLocalStore } from '../utils/utils';
import { LOCAL_STORAGE } from '../data/enums';
import { JWT, SignInData } from '../data/interfaces';
import { SignUpData } from '../../../data/interfaces';

// -----------------------------------------------------------------
// U t i l s
// -----------------------------------------------------------------

// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
/**
 * Sign in method through SignInData interface: once completed,
 * set token in local storage and update User object in redux state
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {SignInData} data - Authentication data
 * @param {any} customParams - Any customised parameters
 */
export const signIn =
    (data: SignInData, customParams?: any) => async (dispatch: Dispatch<SignInAction>) => {
        try {
            dispatch({ type: SIGN_IN_TYPES.PENDING });

            const response = await axiosApiInstance.post(ENDPOINTS.SIGN_IN, {
                ...{
                    email: data.username,
                    password: data.password,
                },
                ...customParams,
            });

            console.log('BP__ TRace after API call', response.data);

            // Updating the JWT in local storage
            localStorage.setItem(
                LOCAL_STORAGE.JWT,
                JSON.stringify({
                    access_token: response.data.access,
                    confirm_token: response.data.confirm_token,
                    refresh_token: response.data.refresh,
                    payload: response.data.payload,
                }),
            );

            // Login on mattermost
            // await signInOnMattermost(data);

            dispatch({
                type: SIGN_IN_TYPES.COMPLETED,
                payload: response.data.payload,
            });
        } catch (exception) {
            const err: any = exception;
            // Store response error and confirm token
            dispatch({
                type: SIGN_IN_TYPES.ADD_ERROR,
                payload: {
                    message: getResponseErrorMessage(err),
                    response: err.response ? err.response.data?.confirm_token : null,
                },
            });
        }
    };

/**
 * Sign up method through SignUpData interface: once completed,
 * set token in local storage and update User object in redux state
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {SignUpData} data - User access credentials
 * @returns {Promise<void>}
 */
export const signUp = async (data: SignUpData): Promise<string | void> => {
    try {
        const response = await axiosApiInstance.post(ENDPOINTS.SIGN_UP, data);
        return response.data.response.confirm_token;
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};

/**
 * Resend the confirmation email to the user who requested it
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {string} confirm_token - Confirmation token used to validate the account
 * @returns {Promise<string>}
 */
export const resendConfirmationEmail = async (confirm_token: string): Promise<string> => {
    try {
        const response = await axiosApiInstance.post(ENDPOINTS.RESEND_CONFIRMATION_EMAIL, {
            confirm_token,
        });
        // Success status
        return response.data.response.email;
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};

/**
 * Check that the specified token confirmation is correct. If so, set the user as "verified".
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {string} confirm_token - Confirmation token used to validate the account
 * @returns {Promise<string>}
 */
export const validateConfirmToken = async (confirm_token: string): Promise<string> => {
    try {
        const response = await axiosApiInstance.post(ENDPOINTS.VALIDATE_CONFIRM_TOKEN, {
            confirm_token,
        });
        updateAuthUserInLocalStore({
            payload: {
                email_verified: true,
            },
        });
        return response.data.response.email;
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};

/**
 * Requires a new access token by passing the refresh token. The token obtained will then overwrite the expired token.
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {string} refreshToken - Refresh token used to regenerate the access token
 * @returns {Promise<JWT>}
 */
export const refreshAccessToken = async (refreshToken: string): Promise<JWT> => {
    try {
        const response = await axiosApiInstance.post(ENDPOINTS.REFRESH_ACCESS_TOKEN, {
            refresh_token: refreshToken,
        });
        // Check if the user is logged in
        const localStorageJWT: JWT | undefined = getLocalStorageJWT();

        if (localStorageJWT) {
            // Update the local storage jwt data fields
            localStorageJWT.access_token = response.data.response.access_token;
            localStorageJWT.refresh_token = response.data.refresh_token;

            return localStorageJWT;
        }
        throw 'You are not logged in';
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};

/**
 * Complete profile method , create user detail and
 * set user profile_completed = true
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {string} idUser - User ID
 * @param {Partial<User>} data - User data
 * @returns {Promise<void>}
 */
export const completeUserProfile = async (
    idUser: string,
    data: Partial<User>,
): Promise<Partial<User>> => {
    try {
        const payload: Partial<User> = {
            ...data,
            ...{ profile_completed: true },
        };

        await axiosApiInstance.put(ENDPOINTS.SIGN_UP, {
            user: payload,
        });

        updateAuthUserInLocalStore({
            payload: {
                profile_completed: true,
            },
        });

        return payload;
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};

/**
 * Sign out method: once completed, remove token in local storage and update User object in redux state
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {History} history - History reference
 */
export const signOut = (history?: History) => async (dispatch: Dispatch<SignOutAction>) => {
    try {
        dispatch({ type: SIGN_OUT_TYPES.PENDING });

        localStorage.removeItem(LOCAL_STORAGE.JWT);

        // replace state
        if (history) {
            history.push(ROUTES.SIGN_IN, { from: undefined });
        }

        dispatch({
            type: SIGN_OUT_TYPES.COMPLETED,
        });
    } catch (exception) {
        const err: any = exception;
        // Store response error
        dispatch({
            type: SIGN_OUT_TYPES.ADD_ERROR,
            payload: getResponseErrorMessage(err),
        });
    }
};

/**
 * Clear signIn error message from redux store.
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 */
export const cleanSignInErrorMessage = () => (dispatch: Dispatch<SignInAction>) => {
    dispatch({ type: SIGN_IN_TYPES.CLEAR_ERROR });
};

/**
 * Sends a user reset password via email
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {string} email - Email to which the password reset link should be sent
 * @returns {Promise<void>}
 */
export const sendResetPasswordEmail = async (email: string): Promise<void> => {
    try {
        await axiosApiInstance.post(ENDPOINTS.FORGOT_PASSWORD, {
            username: email,
        });
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};

/**
 * Save new chosen password
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {string} idUser - User ID
 * @param {string} newPassword - New password set by the user
 * @returns {Promise<void>}
 */
export const resetPassword = async (idUser: string, newPassword: string): Promise<string> => {
    try {
        const response = await axiosApiInstance.post(ENDPOINTS.RESET_PASSWORD, {
            user_id: idUser,
            password: newPassword,
        });

        return response.data.response.email;
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};

/**
 * Deletes user's profile
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {string} idUser - User ID
 * @returns {Promise<void>}
 */
export const deleteUserProfile = async (idUser: string): Promise<void> => {
    try {
        await axiosApiInstance.post(ENDPOINTS.REMOVE_USER, {
            user_id: idUser,
        });
    } catch (exception) {
        const req: any = exception;
        throw req?.response?.data?.error;
    }
};
