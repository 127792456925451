import React, { ReactElement, useEffect } from 'react';
import { notification } from 'antd';
import { ArgsProps, IconType } from 'antd/lib/notification';
import { useTranslation } from 'react-i18next';

interface Props {
    children: any;
}

export interface INotificationContext {
    openErrorNotification: (
        typeIgnored: IconType,
        propsIgnored: ArgsProps,
        isTranslationIgnored?: boolean,
    ) => void;
    errorNotificationWhitelist: () => Array<string>;
}

export const NotificationContext = React.createContext<INotificationContext>({
    openErrorNotification: () => {},
    errorNotificationWhitelist: (): Array<string> => [],
});

export const NotificationContextProvider = ({ children }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Returns the list of errors NOT to be printed on the screen
     *
     * @function
     * @returns {Array<string>}
     */
    const errorNotificationWhitelist = (): Array<string> => {
        return [
            'refresh_token_expired',
            'access_token_expired',
            'internal_server_error',
            'unauthorized',
            'Missing Authorization Token: unauthorized',
        ];
    };

    /**
     * Opens the notification and set the state
     *
     * @function
     * @param {IconType} type - Error icon based on error type
     * @param {GenericObject} anonymousObject - Input object used for deconstruction
     * @param {GenericObject} anonymousObject.description - Path of the translation json describing the type of error
     * @param {boolean} isTranslation - If true, assumes that the passed error is already a translated string
     */
    const openErrorNotification = (
        type: IconType,
        { description: desc, ...restProps }: any,
        isTranslation: boolean = false,
    ): void => {
        // Definition of the list of errors for which you do NOT want to show the notification
        const whiteList: Array<string> = errorNotificationWhitelist();

        // If the error is whitelisted, proceed no further.
        if (typeof desc === 'string' && whiteList.includes(desc)) return;

        // Declaration of the variable containing the description of the notification
        let description: React.ReactNode;

        if (typeof desc === 'string') {
            // The description passed is a string: if it is not already a translation, translate it
            description = !isTranslation ? t([desc, 'errors.general.description']) : desc;
        } else {
            // The description passed is not a string, but any other ReactNode. Rendering it as it is
            description = !desc ? t('errors.general.description') : desc;
        }
        // Open the notification
        notification[type]({ ...restProps, ...{ description } });
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Notification provider] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Notification provider] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <NotificationContext.Provider
            value={{
                openErrorNotification,
                errorNotificationWhitelist,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
