import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../data/enums';
import { getLanguages } from '../../redux/languages/action';
import useQueries from '../useQueries';

/**
 * @param {string} lang - Lang iso code
 * @param {any} onSuccess - Success callback
 * @returns {UseQueryResult<any, any>}
 */
export const useGetLanguagesCsvTemplateQuery = (
    lang: string,
    onSuccess?: any,
): UseQueryResult<any, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_LANGUAGES_CSV_TEMPLATE],
        async () => {
            return getLanguages({
                lang,
                format: 'csv',
            });
        },
        getFetchRequestQueryParams({
            enabled: false,
            onSuccess,
        }),
    );
};
