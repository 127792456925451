import { useEffect } from 'react';
import { Locale } from 'antd/lib/locale-provider';

import arEG from 'antd/lib/locale/ar_EG';
import azAZ from 'antd/lib/locale/az_AZ';
import bgBG from 'antd/lib/locale/bg_BG';
import byBY from 'antd/lib/locale/by_BY';
import caES from 'antd/lib/locale/ca_ES';
import csCZ from 'antd/lib/locale/cs_CZ';
import daDK from 'antd/lib/locale/da_DK';
import deDE from 'antd/lib/locale/de_DE';
import elGR from 'antd/lib/locale/el_GR';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import etEE from 'antd/lib/locale/et_EE';
import faIR from 'antd/lib/locale/fa_IR';
import fiFI from 'antd/lib/locale/fi_FI';
import frFR from 'antd/lib/locale/fr_FR';
import gaIE from 'antd/lib/locale/ga_IE';
import glES from 'antd/lib/locale/gl_ES';
import heIL from 'antd/lib/locale/he_IL';
import hiIN from 'antd/lib/locale/hi_IN';
import hrHR from 'antd/lib/locale/hr_HR';
import huHU from 'antd/lib/locale/hu_HU';
import hyAM from 'antd/lib/locale/hy_AM';
import idID from 'antd/lib/locale/id_ID';
import itIT from 'antd/lib/locale/it_IT';
import isIS from 'antd/lib/locale/is_IS';
import jaJP from 'antd/lib/locale/ja_JP';
import kmrIQ from 'antd/lib/locale/kmr_IQ';
import knIN from 'antd/lib/locale/kn_IN';
import kkKZ from 'antd/lib/locale/kk_KZ';
import koKR from 'antd/lib/locale/ko_KR';
import ltLT from 'antd/lib/locale/lt_LT';
import lvLV from 'antd/lib/locale/lv_LV';
import mkMK from 'antd/lib/locale/mk_MK';
import mnMN from 'antd/lib/locale/mn_MN';
import msMY from 'antd/lib/locale/ms_MY';
import nbNO from 'antd/lib/locale/nb_NO';
import neNP from 'antd/lib/locale/ne_NP';
import nlNL from 'antd/lib/locale/nl_NL';
import plPL from 'antd/lib/locale/pl_PL';
import ptBR from 'antd/lib/locale/pt_BR';
import roRO from 'antd/lib/locale/ro_RO';
import ruRU from 'antd/lib/locale/ru_RU';
import skSK from 'antd/lib/locale/sk_SK';
import srRS from 'antd/lib/locale/sr_RS';
import slSI from 'antd/lib/locale/sl_SI';
import svSE from 'antd/lib/locale/sv_SE';
import taIN from 'antd/lib/locale/ta_IN';
import thTH from 'antd/lib/locale/th_TH';
import trTR from 'antd/lib/locale/tr_TR';
import ukUA from 'antd/lib/locale/uk_UA';
import viVN from 'antd/lib/locale/vi_VN';
import zhCN from 'antd/lib/locale/zh_CN';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------
interface useLanguageData {
    getLocaleByIsoCode(iso: string): Locale;
    getIso2ByLang(lang: string): string;
}

/**
 * Custom hook
 *
 * @function
 * @class
 * @category Utils
 * @subcategory Hooks
 */
function useLanguage(): useLanguageData {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    const locales: { [key: string]: Locale } = {
        ar: arEG,
        az: azAZ,
        bg: bgBG,
        by: byBY,
        ca: caES,
        cs: csCZ,
        da: daDK,
        de: deDE,
        el: elGR,
        en: enUS,
        es: esES,
        et: etEE,
        fa: faIR,
        fi: fiFI,
        fr: frFR,
        ga: gaIE,
        gl: glES,
        he: heIL,
        hi: hiIN,
        hr: hrHR,
        hu: huHU,
        hy: hyAM,
        id: idID,
        it: itIT,
        is: isIS,
        ja: jaJP,
        kmr: kmrIQ,
        kn: knIN,
        kk: kkKZ,
        ko: koKR,
        lt: ltLT,
        lv: lvLV,
        mk: mkMK,
        mn: mnMN,
        ms: msMY,
        nb: nbNO,
        ne: neNP,
        nl: nlNL,
        pl: plPL,
        pt: ptBR,
        ro: roRO,
        ru: ruRU,
        sk: skSK,
        sr: srRS,
        sl: slSI,
        sv: svSE,
        ta: taIN,
        th: thTH,
        tr: trTR,
        uk: ukUA,
        vi: viVN,
        zh: zhCN,
    };

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Returns the configuration object "Locale" from the antd library
     *
     * @param {string} iso - ISO code of the language you want to use
     * @function
     * @returns {Locale}
     */
    const getLocaleByIsoCode = (iso: string): Locale => locales[iso];

    /**
     * Returns the ISO2 by passed language or the default "en-GB".
     *
     * @param {string} lang - Language ISO code.
     * @function
     * @returns {string}
     */
    const getIso2ByLang = (lang: string): string => {
        return lang === 'en' || lang.substring(0, 3) === 'en-' ? 'en-GB' : lang.substring(0, 2);
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[useLanguage] init');
    };
    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[useLanguage] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    return {
        getLocaleByIsoCode,
        getIso2ByLang,
    };
}

export default useLanguage;
