import { JWT } from '../data/interfaces';
import { LOCAL_STORAGE } from '../data/enums';
import { GenericObject } from '../../../data/interfaces';

/**
 * Updates the user's payload stored in the JWT object in local storage
 *
 * @function
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @param {Object} data - Object containing the information to be updated in local storage
 */

/**
 * Returns the JWT object stored in local storage
 *
 * @function
 * @class
 * @category HTTP Methods
 * @subcategory Auth
 * @returns {JWT | undefined}
 */
export const getLocalStorageJWT = (): JWT | undefined => {
    // Get JWT string object stored in local storage
    const encodedLocalStorageJWT: string | null = localStorage.getItem(LOCAL_STORAGE.JWT);

    // In case it exists and is valued, transform it into an object
    return !!encodedLocalStorageJWT ? JSON.parse(encodedLocalStorageJWT) : undefined;
};

export const updateAuthUserInLocalStore = (data: GenericObject): void => {
    // Check if the user is logged in
    const localStorageJWT: any = getLocalStorageJWT();

    if (!!localStorageJWT) {
        // Loop each key of the object
        Object.keys(data).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                // Overwrite only user properties passed as argument
                localStorageJWT[key] = { ...localStorageJWT[key], ...data[key] };
            }
        });

        // Update the data in the local storage
        localStorage.setItem(LOCAL_STORAGE.JWT, JSON.stringify(localStorageJWT));
    }
};
