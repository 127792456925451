/* eslint-disable no-return-await */
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/layout/header/header';
import TableList from '../../../modules/table-list/tableList';
import { useWindowSize } from 'react-use';
import { ColumnsType } from 'antd/lib/table';
import { ENDPOINTS, QUERIES_KEYS } from '../../../data/enums';
import { axiosApiInstance } from '../../../api/apiService';
import moment from 'moment';
import { Button, Tooltip, notification, Spin } from 'antd';
import { LogType } from '@marinelli/shared-types';
import { INotificationContext, NotificationContext } from '../../../providers/notification';
import { ErrorLog, TablePayload } from '../../../data/interfaces';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

const ErrorHandlingView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Access the width of the viewport
    const { width: viewportWidth } = useWindowSize();

    // Access to notification manager hook that you may use to open and close notifications.
    const { openErrorNotification } = useContext<INotificationContext>(NotificationContext);

    // Table columns setting
    const tableColumns: ColumnsType<any> = [
        {
            title: t('screens.private.errorHandling.table.type'),
            dataIndex: ['type'],
            className: 'error-type-column',
            render: (type: LogType) => t(`screens.private.errorHandling.table.${type}.name`),
        },
        {
            title: t('screens.private.errorHandling.table.message'),
            render: ({ type, payload }: ErrorLog) =>
                t(`screens.private.errorHandling.table.${type}.description`, {
                    payload,
                }),
        },
        {
            title: t('screens.private.errorHandling.table.date'),
            dataIndex: ['created_at'],
            className: 'date-column',
            render: (date: number) => moment(new Date(date)).format('DD/MM/YYYY'),
        },
        {
            title: t('screens.private.errorHandling.table.action.title'),
            className: 'action-column',
            render: (error: ErrorLog) => (
                <>
                    <Tooltip title={t('screens.private.errorHandling.table.action.delete')}>
                        <Button
                            ghost
                            type="primary"
                            shape="circle"
                            icon={<i className="ri-delete-bin-6-line" />}
                            onClick={() => onDeleteError(error.id)}
                        />
                    </Tooltip>
                </>
            ),
        },
    ];

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [errorList, setErrorList] = useState<ErrorLog[]>([]);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    const countErrors = async (filters?: string[], search?: string): Promise<number> => {
        const { data } = await axiosApiInstance.get(ENDPOINTS.ERRORS, {
            params: { filters, search },
        });

        return data.count;
    };

    const getErrors = async (params: TablePayload): Promise<Error[]> => {
        const { data } = await axiosApiInstance.get(ENDPOINTS.ERRORS, { params });
        return data.errors;
    };

    const onDeleteError = async (errorId: string): Promise<void> => {
        setIsDeleting(true);

        try {
            await axiosApiInstance.delete(`${ENDPOINTS.ERRORS}/${errorId}`);
            setErrorList(errorList.filter((e) => e.id !== errorId));
            // Print success to the user
            notification.success({
                message: t('success.general.title'),
                description: t('success.general.description'),
            });
        } catch (exception) {
            openErrorNotification('error', {
                message: t('errors.general.title'),
                description: t('errors.general.description'),
            });
        } finally {
            setIsDeleting(false);
        }
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ErrorHandlingView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ErrorHandlingView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <div className="main-card">
            <Header />
            <div className="error-handling-container">
                <h1>{t('screens.private.errorHandling.title')}</h1>
                <p>{t('screens.private.errorHandling.subTitle')}</p>
                <Spin size="default" spinning={isDeleting}>
                    <TableList
                        queryKey={QUERIES_KEYS.GET_ERRORS}
                        viewportWidth={viewportWidth}
                        mobileColumnTitle={['message']}
                        columns={tableColumns}
                        data={errorList}
                        onLoadData={(errors: ErrorLog[]) => setErrorList(errors)}
                        counterMethod={countErrors}
                        loadMethod={{ name: getErrors, filters: ['message'] }}
                    />
                </Spin>
            </div>
        </div>
    );
};
export default ErrorHandlingView;
