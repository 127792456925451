import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../data/enums';
import { exportQuote } from '../../redux/export/action';
import { IExportQuoteForm } from '../../screens/private/export/tabs/quote';
import useQueries from '../useQueries';

/**
 * @param {IExportQuoteForm} data - Order filters
 * @param {any} onSuccess - Success callback
 * @returns {UseQueryResult<any, any>}
 */
export const useExportQuoteQuery = (
    data: IExportQuoteForm,
    onSuccess?: any,
): UseQueryResult<any, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.EXPORT_QUOTE],
        async () => {
            return exportQuote(data);
        },
        getFetchRequestQueryParams({
            enabled: false,
            onSuccess,
        }),
    );
};
