import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../data/enums';
import { getPrices } from '../../redux/prices/action';
import { pruneEmpty } from '../../utils/utils';
import useQueries from '../useQueries';

/**
 * @param {string} lang - Lang iso code
 * @param {string} reseller - Reseller name
 * @param {any} onSuccess - Success callback
 * @returns {UseQueryResult<any, any>}
 */
export const useGetPricesCsvTemplateQuery = (
    lang?: string,
    reseller?: string,
    onSuccess?: any,
): UseQueryResult<any, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_PRICES_CSV_TEMPLATE],
        async () => {
            return getPrices(
                pruneEmpty({
                    lang,
                    reseller,
                }),
            );
        },
        getFetchRequestQueryParams({
            enabled: false,
            onSuccess,
        }),
    );
};
