import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Upload, Form, Select, notification, Row, Col } from 'antd';
import { RcFile } from 'antd/lib/upload';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    onUpload: (entity: 'bases' | 'configs', data: RcFile) => Promise<void>;
}

const ImportCsv = ({ onUpload, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [isLoading, setLoading] = useState<boolean>(false);
    // Internal state that keeps track of the uploaded file (if available)
    const [file, setFile] = useState<RcFile | undefined>(undefined);
    // Internal state that keeps track of the entity to be imported
    const [entity, setEntity] = useState<'bases' | 'configs'>('bases');

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    const handleSubmit = async (): Promise<void> => {
        try {
            setLoading(true);
            if (!!file) await onUpload(entity, file);
            notification.success({
                message: t('success.general.title'),
                description: t('success.import_completed'),
            });
        } catch (exception) {
            notification.error({
                message: t('errors.general.title'),
                description: t('server.errors.import_failed'),
            });
        } finally {
            setLoading(false);
        }
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ImportCsv] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ImportCsv] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    useEffect(() => console.log(entity, file), [file, entity]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <Row>
            <Col xs={12} className="text-container">
                <h1>{t('screens.private.import.title')}</h1>
                <p className="preline">{t(`screens.private.import.tabs.import.subTitle`)}</p>
            </Col>

            <Col xs={12}>
                <Form layout="vertical" size="large">
                    <Form.Item>
                        <Select
                            showArrow
                            placeholder={t(`screens.private.import.tabs.import.placeholder`)}
                            value={entity}
                            onChange={setEntity}
                        >
                            <Select.Option value="bases">
                                {t(`screens.private.import.tabs.import.bases`)}
                            </Select.Option>
                            <Select.Option value="configs">
                                {t(`screens.private.import.tabs.import.configs`)}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Upload.Dragger
                            name="file"
                            accept=".csv"
                            maxCount={1}
                            multiple={false}
                            customRequest={() => {}}
                            fileList={!!file ? [file] : undefined}
                            onRemove={() => setFile(undefined)}
                            onChange={(info) => setFile(info.file.originFileObj)}
                        >
                            <p className="drag-icon">
                                <i className="ri-upload-2-fill" />
                            </p>
                            <h6>{t('screens.private.import.upload.title', { format: '.csv' })}</h6>
                            <p>{t('screens.private.import.upload.description')}</p>
                        </Upload.Dragger>
                    </Form.Item>

                    <div className="bottom-container">
                        <Form.Item>
                            <Button
                                type="primary"
                                disabled={!file}
                                loading={isLoading}
                                onClick={handleSubmit}
                            >
                                {t(`screens.private.import.tabs.import.bottom.button`)}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Col>
        </Row>
    );
};
export default ImportCsv;
