import React, { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button, Card, Col, Form, Input, Row, Image } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SignInState } from '../../../modules/authentication/context/signIn/reducer';
import { cleanSignInErrorMessage, signIn } from '../../../modules/authentication/context/action';
import { SignInData } from '../../../modules/authentication/data/interfaces';
import { INotificationContext, NotificationContext } from '../../../providers/notification';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

interface FormData {
    username: string;
    password: string;
}

/**
 * View representing the page which deals with authentication within the application of a registered user.
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const SignInView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Reference to Redux store dispatch function: use it to dispatch actions.
    const dispatch = useDispatch();

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Access to notification manager hook that you may use to open and close notifications.
    const { openErrorNotification } = useContext<INotificationContext>(NotificationContext);

    // -----------------------------------------------------------------
    // f o r m
    // -----------------------------------------------------------------
    // Initialize React Hook Form
    const { handleSubmit, control, errors, register, formState } = useForm<FormData>({
        mode: 'onChange',
        defaultValues: {
            username: '',
            password: '',
        },
        resolver: yupResolver(
            yup.object<SignInData>().shape({
                username: yup
                    .string()
                    .trim()
                    .email(t('screens.public.signin.form.errors.bad_email'))
                    .required(t('screens.public.signin.form.errors.email_required'))
                    .nullable(),
                password: yup
                    .string()
                    .trim()
                    .required(t('screens.public.signin.form.errors.password_required'))
                    .nullable(),
            }),
        ),
    });

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    /**
     * This hook accesses to the signin data stored in the redux store's state.
     *
     * @function
     */
    const { pending, errorMessage } = useSelector(
        ({ signInReducer }: { signInReducer: SignInState }) => signInReducer,
    );

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Handle submit form
     *
     * @function
     * @param {FormData} data - Form data
     * @returns {void}
     */
    const handleSubmitForm = (data: FormData): void => {
        const { axiosApiInstance } = require('../../../api/apiService');

        dispatch(signIn(data, undefined));
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Render form
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderForm = (): ReactElement | null => (
        <Form layout="vertical" size="large" onFinish={handleSubmit(handleSubmitForm)}>
            <Row gutter={[{ sm: 30 }, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h2>{t('screens.public.signin.title')}</h2>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                        className="input-container"
                        label={<h6>{t('screens.public.signin.form.username')}</h6>}
                        validateStatus={errors.username ? 'error' : ''}
                        help={errors.username?.message}
                    >
                        <Controller
                            as={
                                <Input
                                    ref={register}
                                    disabled={pending}
                                    className="input"
                                    placeholder={t('screens.public.signin.form.username')}
                                />
                            }
                            name="username"
                            control={control}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                        className="input-container"
                        label={<h6>{t('screens.public.signin.form.password')}</h6>}
                        validateStatus={errors.password ? 'error' : ''}
                        help={errors.password?.message}
                    >
                        <Controller
                            as={
                                <Input.Password
                                    ref={register}
                                    disabled={pending}
                                    className="input"
                                    placeholder={t('screens.public.signin.form.password')}
                                />
                            }
                            name="password"
                            control={control}
                        />
                    </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-right">
                    <Link className="link-label" to={ROUTES.FORGOT_PASSWORD}>
                        {t('screens.public.signIn.forgotPassword')}
                    </Link>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item>
                        <section className="submit-btn">
                            <Button
                                type="primary"
                                disabled={!formState.isValid}
                                loading={pending}
                                htmlType="submit"
                                size="large"
                            >
                                {t('screens.public.signin.form.submit')}
                            </Button>
                        </section>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[SignInView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SignInView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to errorMessage in order to handle sign in error message
     */
    useEffect(() => {
        if (errorMessage) {
            dispatch(cleanSignInErrorMessage());

            // Print error to the user
            openErrorNotification('error', {
                message: t('errors.general.title'),
                description: `server.errors.${errorMessage}`,
            });
        }
    }, [errorMessage]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <div id="sign-in">
            {/* <Image src={_environment.assets.logo} preview={false} /> */}
            <Card id="main">
                <header className="card-header">
                    <Image src="assets/images/resources/logo/marinelli.png" preview={false} />
                    <Image src="assets/images/resources/logo/mimo.png" preview={false} />
                </header>
                {renderForm()}
                <div />
            </Card>
        </div>
    );
};
export default SignInView;
