/**
 * Cookies variables
 *
 * @enum {string}
 * @alias Cookies
 * @category Enum
 */
export enum COOKIES {
    COOKIES_ACCEPTED = 'cookies_accepted',
    INTRO = 'intro',
}

/**
 * Default values for Arango
 *
 * @enum {number}
 * @alias DB_Defaults
 * @category Enum
 */
export enum DB_DEFAULTS {
    OFFSET = 0,
    LIMIT = 30,
}
