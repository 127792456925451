import { Reseller } from '@marinelli/shared-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Col, Form, Input, notification, Row, Select, Spin } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { getAllResellers, getAllTranslations, getReseller } from '../../../../redux/import/action';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    onUpsert: (data: Reseller) => Promise<void>;
    onRemove: (resellerid: string) => Promise<void>;
}

const ResellerForm = ({ onUpsert, onRemove, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [isLoading, setLoading] = useState<boolean>(false);
    const [resellers, setResellers] = useState<string[]>([]);
    const [translations, setTranslations] = useState<string[]>([]);

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const { handleSubmit, control, formState, register, watch, reset } = useForm<Reseller>({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object().shape({
                id: yup.string().required("L'id del reseller è richiesto"),
                name: yup.string().required('Il nome del reseller è richiesto'),
                color: yup.string().required('Il colore primario del reseller è richiesto'),
                countries: yup.array().of(yup.string()),
                show_mrn_logos: yup.boolean(),
                allow_message_to_host: yup.boolean(),
                is_embedded_in_iframe: yup.boolean(),
                websiteUrl: yup
                    .string()
                    .required('Il nome del reseller è richiesto')
                    .url('Deve essere un URL'),
            }),
        ),
    });

    const watchingResellerId = watch('id');

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    const onSubmit = async (formData: Reseller): Promise<void> => {
        try {
            setLoading(true);
            await onUpsert(formData);
            reset({
                id: '',
                name: '',
                color: '',
                countries: [],
                show_mrn_logos: false,
                allow_message_to_host: false,
                is_embedded_in_iframe: false,
                websiteUrl: '',
            });
            // Print success to the user
            notification.success({
                message: t('success.general.title'),
                description: t('success.general.description'),
            });
        } catch (exception) {
            notification.error({
                message: t('errors.general.title'),
                description: t('errors.general.description'),
            });
        } finally {
            setLoading(false);
        }
    };

    const onCancel = async (): Promise<void> => {
        try {
            setLoading(true);
            await onRemove(watchingResellerId);
            reset({
                id: '',
                name: '',
                color: '',
                countries: [],
                show_mrn_logos: false,
                allow_message_to_host: false,
                websiteUrl: '',
            });
            // Print success to the user
            notification.success({
                message: t('success.general.title'),
                description: t('success.general.description'),
            });
        } catch (exception) {
            notification.error({
                message: t('errors.general.title'),
                description: t('errors.general.description'),
            });
        } finally {
            setLoading(false);
        }
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        getAllResellers()
            .then((data) => setResellers(data))
            .catch(console.error);
        getAllTranslations()
            .then((data) => setTranslations(data))
            .catch(console.error);
        // init component
        console.log('[ResellerForm] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ResellerForm] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    useEffect(() => {
        if (!!watchingResellerId)
            getReseller(watchingResellerId)
                .then((data) => reset(data))
                .catch(console.error);
    }, [reset, watchingResellerId]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <Row>
            <Col xs={12} className="text-container">
                <h1>{t('screens.private.import.tabs.resellers.title')}</h1>
                <p className="preline">{t(`screens.private.import.tabs.resellers.subTitle`)}</p>
            </Col>

            <Col xs={12}>
                <Spin spinning={isLoading}>
                    <Form layout="vertical" size="large" onFinish={handleSubmit(onSubmit)}>
                        <Form.Item
                            label={t(`screens.private.import.tabs.resellers.form.id`)}
                            validateStatus={!!formState.errors.id ? 'error' : ''}
                            help={formState.errors.id?.message}
                        >
                            <Controller
                                name="id"
                                control={control}
                                render={({ onChange, value }) => (
                                    <Row justify="space-around">
                                        <Col xs={10}>
                                            <Select
                                                showArrow
                                                showSearch
                                                placeholder="Id del reseller già esistente"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                {resellers.map((reseller: string) => (
                                                    <Select.Option value={reseller}>
                                                        {reseller}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={10}>
                                            <Input
                                                value={value}
                                                onChange={onChange}
                                                placeholder="Id del nuovo reseller"
                                            />
                                        </Col>
                                    </Row>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t(`screens.private.import.tabs.resellers.form.show_mrn_logos`)}
                            validateStatus={!!formState.errors.show_mrn_logos ? 'error' : ''}
                            help={formState.errors.show_mrn_logos?.message}
                        >
                            <Controller
                                name="show_mrn_logos"
                                control={control}
                                render={({ onChange, value }) => (
                                    <Checkbox
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    >
                                        Se spunterai questa casella nel configuratore del reseller
                                        compariranno anche i loghi di Marinelli e Mimo
                                    </Checkbox>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t(
                                'screens.private.import.tabs.resellers.form.is_embedded_in_iframe',
                            )}
                            validateStatus={!!formState.errors.is_embedded_in_iframe ? 'error' : ''}
                            help={formState.errors.is_embedded_in_iframe?.message}
                        >
                            <Controller
                                name="is_embedded_in_iframe"
                                control={control}
                                render={({ onChange, value }) => (
                                    <Checkbox
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    >
                                        Abilitando questa casella andrai a nascondare il logo del
                                        reseller in tutte le schermate del configuratore (il logo
                                        continuerà a comparire nel PDF di riepilogo ordine)
                                    </Checkbox>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t(
                                `screens.private.import.tabs.resellers.form.allow_message_to_host`,
                            )}
                            validateStatus={!!formState.errors.allow_message_to_host ? 'error' : ''}
                            help={formState.errors.allow_message_to_host?.message}
                        >
                            <Controller
                                name="allow_message_to_host"
                                control={control}
                                render={({ onChange, value }) => (
                                    <Checkbox
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    >
                                        Se spunterai questa casella sarà possibile comunicare i
                                        dettagli dell'ordine all' e-commerce del reseller.
                                        <br />
                                        <small>
                                            La comunicazione avverrà tramite &nbsp;
                                            <i>window.postMessage</i>&nbsp; e sarà possibile solo
                                            quando il configuratore è usato in un iframe interno
                                            all'e-commerce del reseller.
                                        </small>
                                    </Checkbox>
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t(`screens.private.import.tabs.resellers.form.name`)}
                            validateStatus={!!formState.errors.name ? 'error' : ''}
                            help={formState.errors.name?.message}
                        >
                            <Controller
                                name="name"
                                control={control}
                                as={<Input ref={register} autoFocus />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t(`screens.private.import.tabs.resellers.form.color`)}
                            validateStatus={!!formState.errors.color ? 'error' : ''}
                            help={formState.errors.color?.message}
                        >
                            <Controller
                                name="color"
                                control={control}
                                as={<Input title="color" ref={register} autoFocus />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t(`screens.private.import.tabs.resellers.form.websiteUrl`)}
                            validateStatus={!!formState.errors.websiteUrl ? 'error' : ''}
                            help={formState.errors.websiteUrl?.message}
                        >
                            <Controller
                                name="websiteUrl"
                                control={control}
                                as={<Input title="websiteUrl" ref={register} autoFocus />}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t(`screens.private.import.tabs.resellers.form.countries`)}
                            validateStatus={!!formState.errors.countries ? 'error' : ''}
                            // help={formState.errors.countries?.pop()?.message}
                        >
                            <Controller
                                name="countries"
                                control={control}
                                render={({ onChange, value }) => (
                                    <Select
                                        showArrow
                                        value={value}
                                        mode="multiple"
                                        onChange={onChange}
                                    >
                                        {translations.map((trns: string) => (
                                            <Select.Option value={trns}>{trns}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Form.Item>

                        <Row className="bottom-container" justify="space-around">
                            <Button
                                color="danger"
                                disabled={!formState.isValid}
                                onClick={handleSubmit(onCancel)}
                            >
                                {t(`screens.private.import.tabs.resellers.bottom.delete`)}
                            </Button>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    disabled={!formState.isValid}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {t(`screens.private.import.tabs.resellers.bottom.button`)}
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Spin>
            </Col>
        </Row>
    );
};
export default ResellerForm;
