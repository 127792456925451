/* eslint-disable global-require */
// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ENDPOINTS } from '../../data/enums';
import { GenericObject } from '../../data/interfaces';

/**
 * Returns the languages list
 *
 * @function
 * @param {GenericObject} params - Request query params
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @returns {Promise<Array<string>>}
 */
export const getLanguages = async (params?: GenericObject): Promise<Array<string>> => {
    try {
        const response: AxiosResponse = await httpClient.get(`${ENDPOINTS.TRANSLATIONS}`, {
            params,
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};
