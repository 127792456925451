import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, DatePicker, Input, Select, notification, Spin } from 'antd';
import moment from 'moment';
import download from 'downloadjs';
import { useExportDataQuery } from '../../../../hooks/react-query/useExportData';
import { pruneEmpty } from '../../../../utils/utils';
import { useGetLanguagesQuery } from '../../../../hooks/react-query/useGetLanguages';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

interface IExportDataForm {
    range?: Array<moment.Moment>;
    configId?: string;
    resellerId?: string;
    lang?: string;
}

const ExportDataView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [lastUpdate, setLastUpdate] = useState<string>(moment().toISOString());

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Subscription to a fetch query
    const { data: availableLanguages, isLoading: isFetchingLanguages } = useGetLanguagesQuery();

    const { register, handleSubmit, control, formState, watch } = useForm<IExportDataForm>({
        mode: 'onChange',
        resolver: yupResolver(
            yup.object().shape({
                range: yup.array().nullable(),
                configId: yup.string().nullable(),
                resellerId: yup.string().nullable(),
                lang: yup.string().nullable(),
            }),
        ),
    });

    const watchFields = watch();

    // Subscription to a fetch query
    const { refetch, isLoading: isFetchingData } = useExportDataQuery(
        pruneEmpty({
            from:
                !!watchFields.range && !!watchFields.range[0]
                    ? watchFields.range[0].toISOString()
                    : undefined,
            to:
                !!watchFields.range && !!watchFields.range[1]
                    ? watchFields.range[1].toISOString()
                    : undefined,
            configId: watchFields.configId,
            resellerId: watchFields.resellerId,
            lang: watchFields.lang,
        }),
        (responseData: any) => {
            download(responseData.content, responseData.fileName, responseData.contentType);

            setLastUpdate(moment().toISOString());

            // Print success to the user
            notification.success({
                message: t('success.general.title'),
                description: t('success.export_completed'),
            });
        },
    );

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Handle submit form
     *
     * @function
     * @returns {void}
     */
    const handleSubmitForm = (): void => {
        refetch();
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ExportDataView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ExportDataView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <>
            <Form
                key={lastUpdate}
                layout="vertical"
                size="large"
                onFinish={handleSubmit(handleSubmitForm)}
            >
                <div className="date-container">
                    <Form.Item
                        validateStatus={!!formState.errors.range ? 'error' : ''}
                        label={t('screens.private.export.tabs.data.form.title')}
                    >
                        <Controller
                            as={
                                <DatePicker.RangePicker
                                    placeholder={[
                                        t('screens.private.export.tabs.data.form.from'),
                                        t('screens.private.export.tabs.data.form.to'),
                                    ]}
                                    ref={register}
                                    disabled={isFetchingData}
                                    autoFocus
                                />
                            }
                            name="range"
                            control={control}
                        />
                    </Form.Item>
                </div>
                <div className="inputs-container">
                    <Form.Item
                        validateStatus={!!formState.errors.configId ? 'error' : ''}
                        help={formState.errors.configId?.message}
                        label={t('screens.private.export.tabs.data.form.configId.title')}
                    >
                        <Controller
                            as={
                                <Input
                                    title="configId"
                                    placeholder={t(
                                        'screens.private.export.tabs.data.form.configId.placeholder',
                                    )}
                                    ref={register}
                                    disabled={isFetchingData}
                                    autoFocus
                                />
                            }
                            name="configId"
                            control={control}
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={!!formState.errors.resellerId ? 'error' : ''}
                        help={formState.errors.resellerId?.message}
                        label={t('screens.private.export.tabs.data.form.resellerId.title')}
                    >
                        <Controller
                            as={
                                <Input
                                    title="resellerId"
                                    placeholder={t(
                                        'screens.private.export.tabs.data.form.resellerId.placeholder',
                                    )}
                                    ref={register}
                                    disabled={isFetchingData}
                                    autoFocus
                                />
                            }
                            name="resellerId"
                            control={control}
                        />
                    </Form.Item>
                    {isFetchingLanguages ? (
                        <Spin />
                    ) : (
                        <Form.Item label={t(`screens.private.export.tabs.quote.form.lang.title`)}>
                            <Controller
                                name="lang"
                                control={control}
                                render={({ onChange, onBlur, value }) => (
                                    <Select
                                        showArrow
                                        placeholder={t(
                                            'screens.private.export.tabs.quote.form.lang.placeholder',
                                        )}
                                        style={{ width: 200 }}
                                        disabled={isFetchingData}
                                        value={value}
                                        onChange={(v: string) => {
                                            onChange(v);
                                        }}
                                        onBlur={onBlur}
                                    >
                                        {availableLanguages?.map((lang: string) => (
                                            <Select.Option value={lang}>{lang}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            />
                        </Form.Item>
                    )}
                </div>
                <div className="bottom-container">
                    <Form.Item>
                        <p>{t('screens.private.export.tabs.data.bottom.text')}</p>
                        <Button
                            type="primary"
                            disabled={!formState.isValid}
                            loading={isFetchingData}
                            htmlType="submit"
                        >
                            {t('screens.private.export.tabs.data.bottom.button')}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};
export default ExportDataView;
