import { ROUTES, ROUTES_KEYS } from './routes';
import PublicLayout from '../../routing/layout/PublicLayout';
import { Page } from '../../routing/data/interfaces';
import PrivacyPolicyView from '../../screens/public/privacyPolicy';
import CookiePolicyView from '../../screens/public/cookiePolicy';
import TermsOfServiceView from '../../screens/public/termsOfService';
import SignInView from '../../screens/public/auth/signIn';
import HomeView from '../../screens/private/home';
import ExportView from '../../screens/private/export';
import ImportView from '../../screens/private/import';
import PrivateLayout from '../../routing/layout/PrivateLayout';
import ErrorHandlingView from '../../screens/private/errorHandling';

// Pages list
export const PAGES: Array<Page> = [
    // Public pages
    {
        exact: false,
        path: ROUTES.SIGN_IN,
        key: ROUTES_KEYS.SIGN_IN,
        auth: false,
        component: SignInView,
        layout: PublicLayout,
    },
    {
        exact: false,
        path: ROUTES.PRIVACY_POLICY,
        key: ROUTES_KEYS.PRIVACY_POLICY,
        auth: false,
        alwaysVisible: true,
        component: PrivacyPolicyView,
        layout: PublicLayout,
    },
    {
        exact: false,
        path: ROUTES.COOKIE_POLICY,
        key: ROUTES_KEYS.COOKIE_POLICY,
        auth: false,
        alwaysVisible: true,
        component: CookiePolicyView,
        layout: PublicLayout,
    },
    {
        exact: false,
        path: ROUTES.TERMS_OF_SERVICE,
        key: ROUTES_KEYS.TERMS_OF_SERVICE,
        auth: false,
        alwaysVisible: true,
        component: TermsOfServiceView,
        layout: PublicLayout,
    },

    // Private routes
    {
        exact: false,
        path: ROUTES.HOME,
        key: ROUTES_KEYS.HOME,
        auth: true,
        component: HomeView,
        layout: PrivateLayout,
        nested: [
            {
                exact: false,
                path: ROUTES.IMPORT,
                key: ROUTES_KEYS.IMPORT,
                auth: true,
                component: ImportView,
                layout: PrivateLayout,
            },
            {
                exact: false,
                path: ROUTES.EXPORT,
                key: ROUTES_KEYS.EXPORT,
                auth: true,
                component: ExportView,
                layout: PrivateLayout,
            },
            {
                exact: false,
                path: ROUTES.ERROR,
                key: ROUTES_KEYS.ERROR,
                auth: true,
                component: ErrorHandlingView,
                layout: PrivateLayout,
            },
        ],
    },
];
