import { Tabs } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/layout/header/header';
import {
    deleteReseller,
    importData,
    importPrices,
    importTranslations,
    upsertAsset,
    upsertReseller,
} from '../../../redux/import/action';
import AssetsForm from './tabs/assets';
import ImportCsv from './tabs/import';
import ResellerForm from './tabs/reseller';
import UploadCsv, { EXPORT_TYPES } from './tabs/upload';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

const ImportView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ImportView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ImportView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <div className="main-card">
            <Header />
            <div className="import-container">
                <div className="import-content">
                    <Tabs defaultActiveKey="1" destroyInactiveTabPane>
                        <Tabs.TabPane
                            key="translations"
                            tab={t('screens.private.import.tabs.translations.title')}
                        >
                            <UploadCsv
                                onUpload={importTranslations}
                                exportType={EXPORT_TYPES.TRANSLATIONS}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="prices"
                            tab={t('screens.private.import.tabs.prices.title')}
                        >
                            <UploadCsv onUpload={importPrices} exportType={EXPORT_TYPES.PRICES} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="reseller"
                            tab={t('screens.private.import.tabs.resellers.title')}
                        >
                            <ResellerForm onUpsert={upsertReseller} onRemove={deleteReseller} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="imports"
                            tab={t('screens.private.import.tabs.import.title')}
                        >
                            <ImportCsv onUpload={importData} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key="assets"
                            tab={t('screens.private.import.tabs.assets.title')}
                        >
                            <AssetsForm onUpsert={upsertAsset} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
export default ImportView;
