import { Route, Switch } from 'react-router-dom';
import React, { ReactElement, useEffect } from 'react';
import { History } from 'history';
import { Page } from './data/interfaces';
import { PAGES } from '../config/routing/pages';
import PublicLayout from './layout/PublicLayout';
import NotFoundView from '../screens/public/notFound';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    history: History;
}

const Routes = ({ history, ...restProps }: any): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Render a navigation route content
     *
     * @function
     * @param {Page} page - Page object to render
     * @param {any} props - Props to pass in input to the component
     * @returns {React.ReactNode}
     */
    const renderRouteContent = (page: Page, props: any): React.ReactNode => (
        <page.layout
            history={props.history}
            key={page.key}
            alwaysVisible={page.alwaysVisible}
            {...restProps}
        >
            <page.component props />
        </page.layout>
    );

    /**
     * Render a navigation route
     *
     * @function
     * @param {Page} page - Page object to render
     * @returns {React.ReactNode}
     */
    const renderRoute = (page: Page): React.ReactNode => (
        <Route
            key={page.key}
            exact
            path={page.path}
            render={(props) => renderRouteContent(page, props)}
        />
    );

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Routes] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Routes] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    return (
        <Switch>
            {PAGES.map((page) => (
                <Route
                    key={page.key}
                    exact={page.exact}
                    path={page.path}
                    render={(props) =>
                        page.nested ? (
                            <Switch>
                                {renderRoute({ ...page, ...{ exact: true } })}
                                {page.nested.map((item: Page) => renderRoute(item))}
                            </Switch>
                        ) : (
                            renderRouteContent(page, props)
                        )
                    }
                />
            ))}
            <Route
                render={(props) => (
                    <PublicLayout history={props.history} {...restProps}>
                        <NotFoundView {...props} />
                    </PublicLayout>
                )}
            />
        </Switch>
    );
};

export default Routes;
