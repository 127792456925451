export enum SIGN_OUT_TYPES {
    PENDING = 'SIGN_OUT_PENDING',
    COMPLETED = 'SIGN_OUT_COMPLETED',
    ADD_ERROR = 'SIGN_OUT_ADD_ERROR',
    CLEAR_ERROR = 'SIGN_OUT_CLEAR_ERROR',
}

interface Pending {
    type: SIGN_OUT_TYPES.PENDING;
}

interface Completed {
    type: SIGN_OUT_TYPES.COMPLETED;
}

interface AddError {
    type: SIGN_OUT_TYPES.ADD_ERROR;
    payload: string;
}

interface ClearError {
    type: SIGN_OUT_TYPES.CLEAR_ERROR;
}

export type SignOutAction = Pending | Completed | AddError | ClearError;
