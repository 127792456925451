// -----------------------------------------------------------------
// D E V E L O P M E N T: env configuration
// -----------------------------------------------------------------
// @ts-ignore
import { Environment } from './_environment';

const staging: Environment = {
    version: '1.0.0',
    api: {
        baseURL: 'https://api-test.marinellikitchens.com',
    },
    urls: {
        fo: 'https://test.marinellikitchens.com',
        bo: 'https://backoffice-test.marinellikitchens.com',
    },
    showHandledLogs: true,
    sentry: {
        dsn: 'https://b282f5e0dfe54463a559f70b5682a0a6@o459238.ingest.sentry.io/5619656',
        enabled: true,
    },
    assets: {
        logo: '/assets/images/resources/logo/marinelli.png',
    },
    i18n: {
        languages: ['it', 'en'],
        fallbackLanguage: 'en',
    },
    browserMinumumVersions: [
        {
            browser: 'chrome',
            label: 'Google Chrome',
            downloadUrl: 'https://www.google.com/chrome',
            version: '64.0.0',
        },
        {
            browser: 'firefox',
            label: 'Firefox',
            downloadUrl: 'https://www.mozilla.org/firefox',
            version: '58.0.0',
        },
        {
            browser: 'safari',
            label: 'Safari',
            downloadUrl: 'https://support.apple.com/downloads/safari',
            version: '12.0.0',
        },
        {
            browser: 'ios',
            label: 'Safari iOS',
            downloadUrl: 'https://support.apple.com/downloads/safari',
            version: '12.0.0',
        },
        {
            browser: 'crios',
            label: 'Chrome iOS',
            downloadUrl: 'https://www.google.com/chrome',
            version: '1.0.0',
        },
        {
            browser: 'fxios',
            label: 'Firefox iOS',
            downloadUrl: 'https://www.mozilla.org/firefox',
            version: '1.0.0',
        },
    ],
};

export default staging;
