import { IGetOrders } from '@marinelli/shared-types';
import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../data/enums';
import { exportData } from '../../redux/export/action';
import useQueries from '../useQueries';

/**
 * @param {IGetOrders} data - Order filters
 * @param {any} onSuccess - Success callback
 * @returns {UseQueryResult<any, any>}
 */
export const useExportDataQuery = (
    data: IGetOrders | undefined,
    onSuccess?: any,
): UseQueryResult<any, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.EXPORT_DATA],
        async () => {
            return exportData(data);
        },
        getFetchRequestQueryParams({
            enabled: false,
            onSuccess,
        }),
    );
};
