export enum ROUTES {
    PRIVACY_POLICY = '/privacy-policy',
    COOKIE_POLICY = '/cookie-policy',
    TERMS_OF_SERVICE = '/terms-of-service',
    HOME = '/',
    IMPORT = '/import',
    EXPORT = '/export',
    ERROR = '/errors',
    SIGN_IN = '/sign-in',
}

export enum ROUTES_KEYS {
    HOME = 'home',
    IMPORT = 'import',
    EXPORT = 'export',
    ERROR = '/errors',
    PRIVACY_POLICY = 'privacy-policy',
    COOKIE_POLICY = 'cookie-policy',
    TERMS_OF_SERVICE = 'terms-of-service',
    SIGN_IN = 'sign-in',
}
