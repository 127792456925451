// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { IGetOrders } from '@marinelli/shared-types';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ENDPOINTS } from '../../data/enums';
import { IExportQuoteForm } from '../../screens/private/export/tabs/quote';

/**
 * Returns the pdf order file
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IGetOrders} data - Order filters
 * @returns {Promise<any>}
 */
export const exportQuote = async (data: IExportQuoteForm): Promise<any> => {
    try {
        const response: AxiosResponse = await httpClient.get(
            `${ENDPOINTS.ORDERS}/${data.orderId}`,
            {
                responseType: 'blob',
            },
        );
        const fileName = `${data.orderId}.pdf`;

        return {
            contentType: response.headers['content-type'],
            content: response.data,
            fileName,
        };
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};

/**
 * Returns the pdf order file
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IGetOrders} data - Order filters
 * @returns {Promise<any>}
 */
export const exportData = async (data: IGetOrders | undefined): Promise<any> => {
    try {
        const response: AxiosResponse = await httpClient.get(`${ENDPOINTS.ORDERS}`, {
            params: data,
        });
        const fileName = `${data?.configId || 'export'}.csv`;

        return {
            contentType: response.headers['content-type'],
            content: response.data,
            fileName,
        };
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};
