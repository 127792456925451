// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { PriceInformations } from '@marinelli/shared-types';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ENDPOINTS } from '../../data/enums';
import { GenericObject } from '../../data/interfaces';
import { IUploadForm } from '../../screens/private/import/tabs/upload';

/**
 * Imports the prices csv file
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IUploadForm} data - Form data
 * @returns {Promise<any>}
 */
export const importPrices = async (data: IUploadForm): Promise<any> => {
    try {
        const formData = new FormData();
        formData.append('file', data.file);

        const response: AxiosResponse = await httpClient({
            method: 'post',
            url: `${ENDPOINTS.PRICES}/${data.lang}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};

/**
 * Returns configuration according to choises history
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {GenericObject} params - Request query params
 * @returns {Promise<PriceInformations>}
 */
export const getPrices = async (params?: GenericObject): Promise<Array<PriceInformations>> => {
    try {
        const response: AxiosResponse = await httpClient.get(ENDPOINTS.PRICES, {
            params,
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err.response.data.message;
    }
};
