import React, { ReactElement, useEffect } from 'react';
import { Image } from 'antd';
import { Browser } from 'detect-browser';
import chromeLogo from './assets/images/browsers/chrome.png';
import firefoxLogo from './assets/images/browsers/firefox.png';
import safariLogo from './assets/images/browsers/safari.png';
import edgeLogo from './assets/images/browsers/edge.png';
import { useTranslation } from 'react-i18next';

const { detect } = require('detect-browser');
const semver = require('semver');

export interface BrowserMinimumVersion {
    browser: Browser;
    label: string;
    downloadUrl: string;
    version: string;
}

interface Props {
    children: ReactElement;
    _environment: any;
}

export interface IBrowserCompatibilityContext {}

export const BrowserCompatibilityContext = React.createContext<IBrowserCompatibilityContext>({});

export const BrowserCompatibilityContextProvider = ({
    _environment,
    children,
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Returns whether the browser is compatible or not
     *
     * @function
     * @returns {boolean}
     */
    const isBrowserCompatible = (): boolean => {
        if (!detect()) return false;

        const bmv: BrowserMinimumVersion | undefined = _environment.browserMinumumVersions.filter(
            (b: BrowserMinimumVersion) => b.browser === detect().name,
        )[0];
        if (!bmv) return false;
        return !semver.gte(bmv.version, detect().version);
    };

    /**
     * Returns the browser image path
     *
     * @param {BrowserMinimumVersion} bmv - Browser minimum version object
     * @function
     * @returns {string}
     */
    const getBrowserImage = (bmv: BrowserMinimumVersion): string => {
        switch (bmv.browser) {
            case 'chrome':
            case 'crios':
                return chromeLogo;
            case 'firefox':
            case 'fxios':
                return firefoxLogo;
            case 'safari':
                return safariLogo;
            case 'edge':
                return edgeLogo;
            default:
                return '';
        }
    };

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[BrowserCompatibility provider] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[BrowserCompatibility provider] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return isBrowserCompatible() ? (
        <BrowserCompatibilityContext.Provider value={{}}>
            {children}
        </BrowserCompatibilityContext.Provider>
    ) : (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flexDirection: 'column',
            }}
        >
            <Image
                src={_environment.assets.logo}
                style={{
                    marginTop: 40,
                    marginBottom: 40,
                }}
                preview={false}
            />
            <h1 style={{}}>{t('screens.obsoleteBrowser.title')}</h1>
            <div
                style={{
                    marginBottom: 50,
                    textAlign: 'center',
                }}
            >
                <span>{t('screens.obsoleteBrowser.description')}</span>
                <br />
                <br />
                <strong>{t('screens.obsoleteBrowser.download')}</strong>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                {_environment.browserMinumumVersions.map((bmv: BrowserMinimumVersion) => (
                    <a
                        href={bmv.downloadUrl}
                        target="_blank"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginLeft: 30,
                            marginRight: 30,
                        }}
                        rel="noreferrer"
                    >
                        <Image
                            src={getBrowserImage(bmv)}
                            style={{ marginBottom: 20 }}
                            preview={false}
                        />
                        <h4>{bmv.label}</h4>
                        <span>{t('screens.obsoleteBrowser.minVersionRequired')}</span>
                        <span>{bmv.version}</span>
                    </a>
                ))}
            </div>
        </div>
    );
};
