/* eslint-disable no-plusplus */
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { GenericObject } from '../data/interfaces';

/**
 * Cleans an object of unnecessary properties (undefined, null, etc). Can be used for example to send data to the WebService
 *
 * @function
 * @class
 * @category Utils
 * @subcategory General
 * @param {Object} obj - Any type of object
 * @param {boolean?} removeZeros - Determines whether to also remove keys that have 0 as a value
 */
export const pruneEmpty = (obj: any, removeZeros: boolean = false): any =>
    (function prune(current) {
        _.forOwn(current, (value, key) => {
            if (
                _.isUndefined(value) ||
                _.isNull(value) ||
                _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isNumber(value) && removeZeros && value === 0) ||
                (_.isObject(value) && !_.isArray(value) && _.isEmpty(prune(value)))
            ) {
                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (_.isArray(current)) _.pull(current, undefined);

        return !_.isEmpty(current) ? current : undefined;
    })(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead

/**
 * Returns the error message of an http response.
 *
 * @function
 * @class
 * @category Utils
 * @subcategory General
 * @deprecated This method will no longer exist as all answers will
 * have to be made standard, so they will already be strings.
 * @param {any | string} err - Error returned as request response
 * @returns {any}
 */
export const getResponseErrorMessage = (err: any | string): any =>
    err.response ? err.response.data.error : err;

/**
 * Handle http error response
 *
 * @function
 * @class
 * @category Utils
 * @subcategory General
 * @param {any | string} err - Error message
 * @returns {any}
 */
export const handleError = (err: any): any => {
    const error = getResponseErrorMessage(err);
    console.trace('[Handled error]', error);

    const sentryWhiteList: Array<string> = ['refresh_token_expired'];

    if (!sentryWhiteList.includes(error)) {
        Sentry.captureException(err, {
            user: undefined,
        });
    }

    throw error;
};

/**
 * Extrapolates the values from the item object where the key is dataIndex
 * if dataIndex is an array then its the path to the nested value
 *
 * @function
 * @class
 * @category Utils
 * @subcategory General
 * @param {GenericObject} item - The object from which extrapolate the data
 * @param {string|string[]} dataIndex - The key (or nested key) for the respective value
 * @returns {any} - Whatever the value is
 */
export const getColumnValueByDataIndex = (
    item: GenericObject,
    dataIndex: string | string[],
): any => {
    if (Array.isArray(dataIndex)) {
        let nestedProps = '';
        dataIndex.forEach((p, i) => {
            nestedProps += `${i > 0 ? '.' : ''}${p}`;
        });

        nestedProps = nestedProps.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        nestedProps = nestedProps.replace(/^\./, ''); // strip a leading dot
        const a = nestedProps.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (!!item && k in item) {
                item = item[k];
            } else {
                return;
            }
        }
        return item;
    }
    return item[dataIndex];
};

/**
 * TODO COMMENTS
 *
 * @function
 * @class
 * @category Utils
 * @subcategory General
 * @param {GenericObject} obj - The object from which extrapolate the data
 * @param {string} prop - The string identifying the property
 * @returns {any}
 */
export const getObjPropByString = (obj: any, prop: string): any => {
    prop = prop.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    prop = prop.replace(/^\./, ''); // strip a leading dot
    const a = prop.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in obj) {
            obj = obj[k];
        } else {
            return;
        }
    }
    return obj;
};
